<template>
  <div>
    <customerTemplate
      v-if="templatePage && pageTypeId"
      :key="new Date()"
      :from-type="3"
      :show-edit-content-button="false"
      :show-edit-components-button="false"
      :show-delete-components-button="false"
      :show-insert-components-button="false"
      :show-sort-components-button="false"
      :template-page="templatePage"
    >
    </customerTemplate>
    <div v-else class="system-maintenance"></div>
  </div>
</template>

<script setup>
// #region 引用
import useSSRInitialStateStore from '@/stores/ssr-state';
import { useSSRContext } from 'vue';
import customerTemplate from '../components/Template.vue';

// #endregion
// #region props/emit

// #endregion

// #region 变量/常量
const route = useRoute();
const configInfo = ref({}); // 配置信息
const columnInfo = ref({}); // 当前栏目信息
const configPageList = ref([]); // 配置页面列表
const pageTypeId = ref(Number(route.params.pageTypeId));

const templatePage = ref([]); // 当前页面信息
// 当前路由栏目id
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 获取栏目信息
 */

onServerPrefetch(async () => {
  const ssrContext = useSSRContext();
  configInfo.value = ssrContext.initialState.data.current.configInfo;
  configPageList.value = ssrContext.initialState.data.current.configPageList;
  columnInfo.value = ssrContext.initialState.data.current.columnInfo;
  templatePage.value = configPageList.value.find(
    (item) => item.id === pageTypeId.value,
  );
  if (columnInfo.value) {
    useHead({
      title: `${columnInfo.value.columnName} - ${configInfo.value.homeTitle}`,
      meta: [
        // 设置栏目名称
        {
          name: 'keywords',
          content: columnInfo.value.columnName,
        },
        // 设置栏目摘要
        {
          name: 'description',
          content: columnInfo.value.digest,
        },
      ],
    });
  }
  ssrContext.initialState.data.current.templatePage = templatePage.value;
});

if (!import.meta.env.SSR) {
  const ssrInitialState = useSSRInitialStateStore();
  templatePage.value = ssrInitialState.data.current.templatePage;
}

// #endregion
</script>
<style lang="scss" scoped>
.system-maintenance {
  display: flex;
  width: 100vw;
  height: 100vh;
  object-fit: fill;
  background: url('./image/System_Maintenance.png');
  background-size: 100% 100%;
}
</style>
