<template>
  <div>
    <customerTemplate
      v-if="configPageList.length > 0"
      :key="new Date()"
      :from-type="3"
      :show-edit-content-button="false"
      :show-edit-components-button="false"
      :show-delete-components-button="false"
      :show-insert-components-button="false"
      :show-sort-components-button="false"
      :template-page="
        configPageList.find((f) => f.pageType === OwPageType.首页)
      "
    >
    </customerTemplate>
  </div>
</template>

<script setup>
// #region 引用
import { OwPageType } from '@/configs/enums/website';
import useSSRInitialStateStore from '@/stores/ssr-state';
import { useSSRContext } from 'vue';
import customerTemplate from '../components/Template.vue';

// #endregion

// #region props/emit

// #endregion

// #region 变量/常量

const configInfo = ref({}); // 配置信息
const configPageList = ref([]); // 配置页面列表

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

onServerPrefetch(async () => {
  const ssrContext = useSSRContext();

  configInfo.value = ssrContext.initialState.data.current.configInfo;
  configPageList.value = ssrContext.initialState.data.current.configPageList;
  if (configInfo.value) {
    useHead({
      title: configInfo.value.homeTitle,
      meta: [
        {
          name: 'keywords',
          content: configInfo.value.homeKeyword,
        },
        {
          name: 'description',
          content: configInfo.value.homeDesc,
        },
      ],
    });
  }
});

if (!import.meta.env.SSR) {
  // 客户端从initialState读取数据
  const ssrInitialState = useSSRInitialStateStore();
  configInfo.value = ssrInitialState.data.current.configInfo;
  configPageList.value = ssrInitialState.data.current.configPageList;
}

// #endregion
</script>
