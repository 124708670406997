import axios from 'axios';
import queryString from 'query-string';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 2000000,
});

// 拦截器 - request
axiosInstance.interceptors.request.use(
  async (config) => {
    config.custom = {
      requestTime: new Date(),
    };
    return config;
  },
  (error) => {
    console.log('error-axiosInstance.interceptors.request', error);
    return Promise.reject(error);
  },
);

// 拦截器 - response
axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log('error-axiosInstance.interceptors.response', error);
    return Promise.reject(error);
  },
);

/**
 * Get
 * @param {string} url
 * @param {Object} [params]
 * @param {Object} [config]
 * @returns {Promise<AxiosResponse<any>>}
 */
axiosInstance.get = (url, params, config) => {
  console.log('get', url, params);
  const axiosConfig = { ...config, method: 'get' };
  if (params) {
    axiosConfig.originData = params;
    axiosConfig.params = params;
    axiosConfig.paramsSerializer = (paramsForSerializer) => {
      return queryString.stringify(paramsForSerializer);
    };
  }

  return axiosInstance.request(url, axiosConfig);
};

/**
 * Json格式的Post（application/json）
 * @deprecated 该方法暂时不要用
 * @param {string} url
 * @param {Object} [data]
 * @param {Object} [config]
 * @returns {Promise<AxiosResponse<any>>}
 */
axiosInstance.postJson = (url, data, config) => {
  return new Promise((resolve, reject) => {
    const axiosConfig = { ...config, method: 'post' };
    if (data) {
      axiosConfig.originData = data;
      axiosConfig.data = data;
    }

    axiosInstance
      .request(url, axiosConfig)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Form格式的Post（application/x-www-form-urlencoded）
 * @param {string} url
 * @param {Object} [data]
 * @param {Object} [config]
 * @returns {Promise<AxiosResponse<any>>}
 */
axiosInstance.postForm = (url, data, config) => {
  return new Promise((resolve, reject) => {
    const axiosConfig = { ...config, method: 'post' };
    if (data) {
      axiosConfig.originData = data;
      axiosConfig.data = queryString.stringify(data);
    }

    axiosInstance
      .request(url, axiosConfig)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * 带文件的Post（multipart/form-data）
 * @param {string} url
 * @param {FormData} [data]
 * @param {Object} [config]
 * @returns {Promise<AxiosResponse<any>>}
 */
axiosInstance.postWithFile = axiosInstance.postJson;

/**
 * Post，使用postForm方法
 */
axiosInstance.post = axiosInstance.postForm;

export default axiosInstance;
