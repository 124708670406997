<template>
  <div class="column-content-form-container">
    <el-form
      ref="ruleFormRef"
      :model="configDataForm"
      :rules="rules"
      label-width="150px"
      class="account-form"
      :size="formSize"
    >
      <div class="card-frame other-card-frame">
        <div class="card-frame-top">基础信息</div>
        <div class="card-frame-container">
          <el-form-item label="背景图片：" prop="backGroundImageObj">
            <zc-upload
              v-model="configDataForm.backGroundImageObj"
              :limit-single-size="1048576"
              :image-thumbnail-width="240"
              type="singleImage"
              :show-no-image="true"
              :small-text="true"
              :enable-popup="false"
              tip="说明：上传的图片大小不得超过1M，建议尺寸560*430px"
              @change="handleBackgroundImageChange"
            >
              <template #singleImageError>
                <div class="el-image__error image-error-show">图片丢失</div>
              </template>
            </zc-upload>
          </el-form-item>
        </div>
      </div>
      <div
        v-for="(item, index) in configDataForm.items"
        :key="item.id"
        class="card-frame other-card-frame"
      >
        <div class="card-frame-top">{{ item.title }}</div>
        <div class="card-frame-container">
          <!-- <el-form-item label=" 封面图：" :prop="`items[${index}].coverImageFileObj`" :rules="rules.coverImageFileObj">
            <zc-upload
              :limitSingleSize="1048576"
              type="singleImage"
               :show-no-image="true"
          :small-text="true"
              @change="handleChange(index)"
              v-model="item.coverImageFileObj"
              :enablePopup="false"
              tip="说明：上传的图片大小不得超过1M，建议尺寸386*227px">
          <template #singleImageError>
            <div class="el-image__error  image-error-show">图片丢失</div>
          </template>
        </zc-upload>
          </el-form-item>
          <el-form-item :label="'背景颜色' + item.sortNum + '：'" :prop="`items[${index}].backgroundColor`" :rules="rules.backgroundColor">
            <el-color-picker v-model="item.backgroundColor" />
          </el-form-item> -->
          <el-form-item
            label="可选栏目："
            :prop="`items[${index}].dataSourceId`"
            :rules="rules.dataSourceId"
          >
            <zc-select
              v-model="item.dataSourceId"
              :data="columnList"
            ></zc-select>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="column-content-btn-container">
      <el-button @click="handleCancel()">取消</el-button>
      <el-button type="primary" @click="handleSave(ruleFormRef)"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script setup>
// #region 引用
import {
  verifyCharacterLength,
  verifyLinkUrl,
} from '@/utils/common/form-verify-util';
import { ColumnType, OwComponentType } from '@/configs/enums/website';

// #endregion

// #region props/emit
/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'update:modelValue',
  'handle-edit-components-content',
]);
/**
 * 属性
 */
const props = defineProps({
  componentData: {
    type: Object,
    default() {},
  },
});
// #endregion

// #region 变量/常量
const { proxy } = getCurrentInstance(); // 当前代理实例

// 栏目数据列表
const columnList = ref([{ value: '', label: '请选择栏目' }]);
// 表单绑定对象
const configDataForm = ref({
  backGroundImageObj: null,
  backgroundImageName: '',
  backgroundImageFileKey: 'backgroundImage',
  title: '',
  linkUrl: '',
  textColor: '#ffffff',
  backgroundColor: '#008bd5',
  items: [
    {
      title: '首页展示栏目',
      sortNum: 1,
      coverImageName: '示例图片2.jpg',
      coverImageFileKey: 'coverImage1',
      backgroundColor: '#1e93ea',
      coverImageFileObj: null,
      dataSourceId: '',
      dataSourceCount: 3,
    },
  ],
});

// 表单size
const formSize = ref('default');
const ruleFormRef = ref();

// 表单规则
const rules = ref({
  backGroundImageObj: [{ required: true, message: '必填项', trigger: 'blur' }],
  coverImageFileObj: [{ required: true, message: '必填项', trigger: 'blur' }],
  dataSourceId: [{ required: true, message: '必填项', trigger: 'blur' }],
  backgroundColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  textColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  title: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 20, trigger: 'blur' },
  ],
  linkUrl: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyLinkUrl, clen: 50, trigger: 'blur' },
  ],
});

/**
 * 可选栏目列表
 */
// columnList.value = props.componentData.contentList;
columnList.value = props.componentData.contentList
  .filter((item) => item.item.columnType === ColumnType.文章列表栏目)
  .map((item) => {
    console.log('object :>>item ', item);
    return item;
  });

if (props.componentData.configData) {
  // 待数据更新完成后才执行
  nextTick(() => {
    configDataForm.value = JSON.parse(props.componentData.configData);
    configDataForm.value.items.forEach((res, index) => {
      res.title = `首页展示栏目`;
      res.coverImageFileObj = {
        name: res.coverImageName,
        url: res.coverImageFullUrl,
      };
    });

    configDataForm.value.backGroundImageObj = {
      name: configDataForm.value.backgroundImageName,
      url: configDataForm.value.backgroundImageFullUrl,
    };
  });
}
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 背景图片改变后调用
 */
const handleBackgroundImageChange = (file) => {
  ruleFormRef.value.validateField('backGroundImageObj', (valid) => {});
};

/**
 * 处理取消
 */
const handleCancel = () => {
  emits('handle-show-edit-form', false);
};
/**
 * 图片改变后调用
 */
const handleChange = (index) => {
  // console.log('index:', index);
  ruleFormRef.value.validateField(
    `items[${index}].coverImageFileObj`,
    (valid) => {},
  );
};
/**
 * 处理保存
 */
const handleSave = (form) => {
  if (!form) return;

  form.validate((valid, fields) => {
    if (valid) {
      /**
       * 校验选择的栏目不能相同
       */

      const dataSourceIdArr = configDataForm.value.items.map(
        (f) => f.dataSourceId,
      );

      const newDataSourceIdArr = [...new Set(dataSourceIdArr)];
      if (newDataSourceIdArr.length !== dataSourceIdArr.length) {
        proxy.$message({
          showClose: true,
          message: '栏目不能相同，请做出调整！',
          type: 'info',
        });
        return;
      }

      // 待数据更新完成后才执行
      nextTick(() => {
        emits('handle-edit-components-content', false);
        emits('update:modelValue', configDataForm.value);
      });
      // TODO 待完成保存逻辑
    } else {
      console.log('fields:', fields);
    }
  });
};
// #endregion
</script>

<style scoped>
.column-content-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin: auto;
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

:deep(.el-form) {
  width: 100%;
  padding: 20px;
}

:deep(.el-color-picker__trigger) {
  width: 100px;
}

.column-content-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: var(--el-color-white);
}

.column-content-btn-container button {
  margin: 8px;
}
</style>
