/**
 * ssr的state对象处理
 */
const useSSRInitialStateStore = defineStore('ssr-state', () => {
  // state
  const data = ref({});

  /**
   * 设置data对象值
   * @param {*} val
   */
  function setData(val) {
    data.value = val;
  }

  return {
    data,
    setData,
  };
});
export default useSSRInitialStateStore;
