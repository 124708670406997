<template>
  <component :is="dom[bundleComponent(styeCode)]"> </component>
</template>
<script setup>
// #region 引用
import { ColumnType, OwPageType } from '@/configs/enums/website';
import { useSSRContext } from 'vue';
import useSSRInitialStateStore from '@/stores/ssr-state';

import {
  addReadCount,
  getMaterialArticlesDetail,
  getMaterialAttachmentsDetail,
  getMaterialImagesDetail,
  getMaterialVideosDetail,
} from '@/apis/modules/auth/website/website-ssr';
import articleDetail from './ArticleDetail.vue';
// #endregion

// #region props/emit

// #endregion

// #region 变量/常量

const configPageList = ref(); // 配置页面列表

const styeCode = ref('ARTICLE_DETAIL_1');
// 栏目信息
const columnInfo = ref();
const route = useRoute();

/**
 * 动态渲染组件
 */
const dom = shallowReactive({
  articleDetail,
});

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 绑定组件
 * @param {*} stye 页面类型
 */
const bundleComponent = (stye) => {
  let component = '';
  switch (stye) {
    case 'ARTICLE_DETAIL_1':
      component = 'articleDetail';
      break;
    case 'ATTACHMENT_DETAIL_1':
      // TODO
      component = 'articleListTwo';
      break;
    case 'VIDEO_DETAIL_1':
      // TODO
      component = 'articleListTwo';
      break;
    case 'AUDIO_DETAIL_1':
      // TODO
      component = 'articleListTwo';
      break;
    default:
      component = 'articleDetail';
      break;
  }
  return component;
};
// 服务端获取数据
onServerPrefetch(async () => {
  const ssrContext = useSSRContext();
  // 栏目信息
  columnInfo.value = ssrContext.initialState.data.current.columnInfo;
  configPageList.value = ssrContext.initialState.data.current.configPageList;
  /**
   * 增加阅读次数
   */

  await addReadCount.use({
    columnId: route.params.columnId,
    materialId: route.params.materialId,
  });

  /**
   * 获取文章详细
   */
  // TODO 后续更具不同栏目类型取不同的参数
  let apiUrl = getMaterialArticlesDetail;
  switch (columnInfo.value.columnType) {
    case ColumnType.文章列表栏目:
      styeCode.value = configPageList.value.find(
        (f) => f.pageType === OwPageType.文章详情页,
      ).owPageStyleCode;
      apiUrl = getMaterialArticlesDetail;
      break;
    case ColumnType.图片列表栏目:
      styeCode.value = configPageList.value.find(
        (f) => f.pageType === OwPageType.图片列表页,
      ).owPageStyleCode;
      apiUrl = getMaterialImagesDetail;
      break;
    case ColumnType.附件列表栏目:
      styeCode.value = configPageList.value.find(
        (f) => f.pageType === OwPageType.附件详情页,
      ).owPageStyleCode;
      apiUrl = getMaterialAttachmentsDetail;
      break;
    case ColumnType.视频列表栏目:
      styeCode.value = configPageList.value.find(
        (f) => f.pageType === OwPageType.视频播放页,
      ).owPageStyleCode;
      apiUrl = getMaterialVideosDetail;
      break;
    default:
      break;
  }
  const res = await apiUrl.use({
    columnId: route.params.columnId,
    materialId: route.params.materialId,
  });
  ssrContext.initialState.data.current.materialArticlesInfo = res.data; // 存储素材信息，后面素材详细展示界面需要
  ssrContext.initialState.data.current.styeCode = styeCode.value; // 存储走那个展示界面
});
// 客户端获取数据，界面要展示的都要在客户端获取一次
if (!import.meta.env.SSR) {
  const ssrInitialState = useSSRInitialStateStore();
  styeCode.value = ssrInitialState.data.current.styeCode;
  configPageList.value = ssrInitialState.data.current.configPageList;
}
// #endregion
</script>
<style scoped></style>
