<template>
  <div class="image-text-form-container">
    <el-form
      ref="ruleFormRef"
      :model="configDataForm"
      :rules="rules"
      label-width="180px"
      :size="formSize"
    >
      <el-form-item label="左侧侧标题：" prop="title">
        <el-input v-model="configDataForm.title" />
      </el-form-item>
      <el-form-item label="副标题：" prop="subTitle">
        <el-input v-model="configDataForm.subTitle" />
      </el-form-item>
      <el-form-item label="左侧内容：" prop="content">
        <el-input
          v-model="configDataForm.content"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 20 }"
        />
      </el-form-item>
      <el-form-item label="背景图片：" prop="backGroundImageObj">
        <zc-upload
          v-model="configDataForm.backGroundImageObj"
          :limit-single-size="1048576"
          :image-thumbnail-width="240"
          type="singleImage"
          :enable-popup="false"
          :show-no-image="true"
          :small-text="true"
          tip="说明：上传的图片大小不得超过1M，建议尺寸1920*480px"
          @change="handleBackgroundImageChange"
        >
          <template #singleImageError>
            <div class="el-image__error image-error-show">图片丢失</div>
          </template>
        </zc-upload>
      </el-form-item>
      <el-form-item label="右侧内容图片：" prop="contentImageObj">
        <zc-upload
          v-model="configDataForm.contentImageObj"
          :limit-single-size="1048576"
          type="singleImage"
          :enable-popup="false"
          :show-no-image="true"
          :small-text="true"
          tip="说明：上传的图片大小不得超过1M，建议尺寸488*318px"
          @change="handleChange"
        >
          <template #singleImageError>
            <div class="el-image__error image-error-show">图片丢失</div>
          </template>
        </zc-upload>
      </el-form-item>
      <el-form-item label="左侧按钮边框文案颜色：" prop="textColor">
        <el-color-picker v-model="configDataForm.textColor" />
      </el-form-item>
      <el-form-item label="左侧按钮背景色：" prop="backgroundColor">
        <el-color-picker v-model="configDataForm.backgroundColor" />
      </el-form-item>
      <el-form-item label="左侧按钮跳转链接：" prop="linkUrl">
        <el-input v-model="configDataForm.linkUrl" />
      </el-form-item>
    </el-form>
    <div class="image-text-btn-container">
      <el-button @click="handleCancel()">取消</el-button>
      <el-button type="primary" @click="handleSave(ruleFormRef)"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script setup>
// #region 引用
import {
  verifyCharacterLength,
  verifyLinkUrl,
} from '@/utils/common/form-verify-util';

// #endregion

// #region props/emit

/**
 * 属性
 */
const props = defineProps({
  componentData: {
    type: Object,
    default() {},
  },
});
/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'update:modelValue',
  'handle-edit-components-content',
]);

// #endregion

// #region 变量/常量
// 栏目数据列表

// 表单size
const formSize = ref('default');
const ruleFormRef = ref();
// 表单绑定对象
const configDataForm = ref({
  contentImageObj: null,
  imageName: '',
  imageFileKey: 'contentImage',
  backGroundImageObj: null,
  backgroundImageName: '',
  backgroundImageFileKey: 'backgroundImage',
  title: '示例标题',
  content: '示例内容',
  textColor: '#ffffff',
  backgroundColor: '#008bd5',
  linkUrl: '',
});

if (props.componentData.configData) {
  configDataForm.value = JSON.parse(props.componentData.configData);

  configDataForm.value.contentImageObj = {
    name: configDataForm.value.imageName,
    url: configDataForm.value.imageFullUrl,
  };

  configDataForm.value.backGroundImageObj = {
    name: configDataForm.value.backgroundImageName,
    url: configDataForm.value.backgroundImageFullUrl,
  };
}

// 表单规则
const rules = ref({
  contentImageObj: [{ required: true, message: '必填项', trigger: 'blur' }],
  backGroundImageObj: [{ required: true, message: '必填项', trigger: 'blur' }],
  textColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  backgroundColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  content: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 50000, trigger: 'blur' },
  ],
  title: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 20, trigger: 'blur' },
  ],
  subTitle: [{ validator: verifyCharacterLength, clen: 20, trigger: 'blur' }],
  linkUrl: [
    // { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyLinkUrl, clen: 100, trigger: 'blur' },
  ],
});
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 图片改变后调用
 */
const handleChange = (file) => {
  ruleFormRef.value.validateField('contentImageObj', (valid) => {});
};

/**
 * 背景图片改变后调用
 */
const handleBackgroundImageChange = (file) => {
  ruleFormRef.value.validateField('backGroundImageObj', (valid) => {});
};

/**
 * 处理取消
 */
const handleCancel = () => {
  emits('handle-show-edit-form', false);
};
/**
 * 处理保存
 */
const handleSave = (form) => {
  if (!form) return;

  form.validate((valid, fields) => {
    if (valid) {
      // console.log('configDataForm-ImageTextEditForm:', configDataForm.value);

      // 待数据更新完成后才执行
      nextTick(() => {
        emits('handle-edit-components-content', false);
        emits('update:modelValue', configDataForm.value);
      });
      // TODO 待完成保存逻辑
    }
  });
};
// #endregion
</script>

<style scoped>
:deep(.el-form) {
  width: 100%;
  padding: 20px;
}

:deep(.el-color-picker__trigger) {
  width: 100px;
}
</style>
