<template>
  <div class="image-text-set-form-container">
    <el-form
      ref="ruleFormRef"
      :model="configDataForm"
      :rules="rules"
      label-width="150px"
      :size="formSize"
    >
      <el-row
        v-for="(item, index) in configDataForm.items"
        :key="index"
        class="el-row-customer"
      >
        <span class="copyright-form-item-form">
          <el-col :span="24">
            <el-form-item
              :label="'文字颜色' + item.sortNum + '：'"
              :prop="`items[${index}].fontColor`"
              :rules="rules.fontColor"
            >
              <el-color-picker v-model="item.textColor" />
            </el-form-item>
            <el-form-item
              :label="'背景颜色' + item.sortNum + '：'"
              :prop="`items[${index}].backgroundColor`"
              :rules="rules.backgroundColor"
            >
              <el-color-picker v-model="item.backgroundColor" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              :label="'背景' + item.sortNum + '：'"
              :prop="`items[${index}].backgroundFileObj`"
              :rules="rules.backgroundFileObj"
            >
              <zc-upload
                v-model="item.backgroundFileObj"
                :limit-single-size="1048576"
                type="singleImage"
                :enable-popup="false"
                :show-no-image="true"
                :small-text="true"
                tip="说明：上传的图片大小不得超过100KB，建议尺寸40*40px"
                @change="handleChange(index)"
              >
                <template #singleImageError>
                  <div class="el-image__error image-error-show">图片丢失</div>
                </template>
              </zc-upload>
            </el-form-item>
            <el-form-item
              :label="'标题' + item.sortNum + '：'"
              :prop="`items[${index}].title`"
              :rules="rules.title"
            >
              <el-input v-model="item.title" style="width: 600px !important" />
            </el-form-item>
            <el-form-item
              :label="'跳转链接' + item.sortNum + '：'"
              :prop="`items[${index}].linkUrl`"
              :rules="rules.linkUrl"
            >
              <el-input
                v-model="item.linkUrl"
                style="width: 600px !important"
              />
            </el-form-item>
          </el-col>
        </span>
        <span class="copyright-form-item-remove">
          <template v-if="configDataForm.items.length > 1">
            <el-link type="danger" @click="handleDel(index)"> 移除 </el-link>
          </template>
          <template v-else>
            <el-tooltip content="至少保留一项版权内容项" placement="top">
              <el-link type="danger" disabled> 移除 </el-link>
            </el-tooltip>
          </template>
        </span>
      </el-row>
      <el-row>
        <el-col :span="24">
          <template v-if="configDataForm.items.length < 9">
            <el-button style="width: 100%" @click="handleAddItem()">
              <el-icon><Plus /></el-icon>添加项</el-button
            >
          </template>
          <template v-else>
            <el-tooltip content="最多添加三项" placement="top">
              <el-button style="width: 100%" disabled>
                <el-icon><Plus /></el-icon>添加项</el-button
              >
            </el-tooltip>
          </template>
        </el-col>
      </el-row>
    </el-form>
    <div class="image-text-set-btn-container">
      <el-button @click="handleCancel()">取消</el-button>
      <el-button type="primary" @click="handleSave(ruleFormRef)"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script setup>
// #region 引用
import {
  verifyCharacterLength,
  verifyLinkUrl,
} from '@/utils/common/form-verify-util';
import { Plus } from '@element-plus/icons-vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  componentData: {
    type: Object,
    default() {},
  },
});
/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'update:modelValue',
  'handle-edit-components-content',
]);

// #endregion

// #region 变量/常量

// 栏目数据列表
const columnList = ref([{ value: '', label: '请选择栏目' }]);

// 表单size
const formSize = ref('default');
const ruleFormRef = ref();

const configDataForm = ref({
  items: [
    {
      sortNum: 1,
      iconName: '示例图片2.jpg',
      iconFileKey: 'icon1',
      title: '任务管理及绩效管理',
      backgroundColor: '#fbbe2d',
      fontColor: '#ffffff',
      linkType: 2,
      linkUrl: 'https://www.baidu.com',
      backgroundFileObj: null,
    },
    {
      sortNum: 2,
      iconName: '示例图片2.jpg',
      iconFileKey: 'icon2',
      title: '养老院管理系统',
      backgroundColor: '#1e93ea',
      fontColor: '#ffffff',
      linkType: 1,
      linkUrl: '/article-list/132',
      backgroundFileObj: null,
    },
    {
      sortNum: 3,
      iconName: '示例图片2.jpg',
      iconFileKey: 'icon3',
      title: '移动电子商务',
      backgroundColor: '#a478db',
      fontColor: '#ffffff',
      linkType: 1,
      linkUrl: '/article-list/132',
      backgroundFileObj: null,
    },
    {
      sortNum: 4,
      iconName: '示例图片2.jpg',
      iconFileKey: 'icon4',
      title: '幼儿园管理系统',
      backgroundColor: '#ef5e61',
      fontColor: '#ffffff',
      linkType: 1,
      linkUrl: '/article-list/132',
      backgroundFileObj: null,
    },
    {
      sortNum: 5,
      iconName: '示例图片2.jpg',
      iconFileKey: 'icon5',
      title: '职业培训管理系统',
      backgroundColor: '#61b400',
      fontColor: '#ffffff',
      linkType: 1,
      linkUrl: '/article-list/132',
      backgroundFileObj: null,
    },
  ],
});
if (props.componentData.configData) {
  configDataForm.value = JSON.parse(props.componentData.configData);
  configDataForm.value.items = configDataForm.value.items.map((f) => ({
    ...f,
    backgroundFileObj: {
      name: f.iconName,
      url: f.iconFullUrl,
    },
  }));
}

// 表单规则
const rules = ref({
  backgroundFileObj: [{ required: true, message: '必填项', trigger: 'blur' }],
  linkUrl: [{ validator: verifyLinkUrl, clen: 100, trigger: 'blur' }],
  fontColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  backgroundColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  title: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 50, trigger: 'blur' },
  ],
});
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 图片改变后调用
 */
const handleChange = (index) => {
  // console.log('index:', index);
  ruleFormRef.value.validateField(
    `items[${index}].backgroundFileObj`,
    (valid) => {},
  );
};

/**
 * 处理取消
 */
const handleCancel = () => {
  emits('handle-show-edit-form', false);
};
/**
 * 处理保存
 */
const handleSave = (form) => {
  if (!form) return;

  form.validate((valid, fields) => {
    if (valid) {
      // console.log('configDataForm-CarouselEditForm:', configDataForm.value);

      // 待数据更新完成后才执行
      nextTick(() => {
        emits('handle-edit-components-content', false);
        emits('update:modelValue', configDataForm.value);
      });
      // TODO 待完成保存逻辑
    }
  });
};

/**
 * 处理删除
 */
const handleDel = (index) => {
  configDataForm.value.items.splice(index, 1);
  //   handleResetRules();
};

/**
 * 添加项目
 */
const handleAddItem = () => {
  // 获取当前最大排序号

  const sortNumArr = configDataForm.value.items.map((f) => {
    return f.sortNum;
  });

  const sortNum = Math.max(...sortNumArr) + 1;

  configDataForm.value.items.push({
    sortNum,
    iconName: '',
    iconFileKey: `icon${sortNum}`,
    title: '',
    backgroundColor: '#ff0000',
    fontColor: '#ffffff',
    linkType: 1,
    linkUrl: '',
    backgroundFileObj: null,
  });
};

// #endregion
</script>

<style scoped>
.image-text-set-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  margin: auto;
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.image-text-set-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: var(--el-color-white);
}

.image-text-set-btn-container button {
  margin: 8px;
}

:deep(.el-form) {
  width: 100%;
  padding: 20px;
}

.el-row-customer {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  padding-top: 10px;
  margin: 10px 0;
  border: 1px solid;
  border-color: var(--el-color-grey-light-45);

  /* flex-direction: column; */
}

.copyright-form-item-form {
  width: calc(100% - 44px) !important;
}

.copyright-form-item-remove {
  width: 24px !important;
  margin: 0 5px;
}
</style>
