<template>
  <div>
    <div v-if="props.topNavComponent" class="index-template-top">
      <component
        :is="topNav"
        :component-data="props.topNavComponent"
        :show-edit-content-button="false"
        :show-edit-components-button="false"
        :show-delete-components-button="false"
        :show-insert-components-button="false"
        :show-sort-components-button="false"
      ></component>
    </div>
  </div>
</template>
<script setup>
// #region 引用
import topNav from '@/views/components/website/top-nav/TopNav.vue';

// #endregion

// #region props/emit
const props = defineProps({
  topNavComponent: {
    type: Object,
    default: null,
  },
});
// #endregion

// #region 变量/常量

/**
 * 顶部导航组件
 */

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

// #endregion
</script>
<style scoped>
.index-template-top {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
