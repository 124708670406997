import { ColumnType } from '@/configs/enums/website';
/**
 * 获取栏目页面url
 * @param {Number} columnType 栏目类型
 * @param {Number} columnId 栏目id
 * @param {Number} previewId 预览模板Id
 * @returns
 */
const getColumnPageUrl = (columnType, columnId, previewId) => {
  if (previewId) {
    return ColumnType.单页面栏目 === columnType
      ? `${import.meta.env.VITE_VUE_APP_WebSite_HOST}/content/single/${columnId}/${previewId}`
      : `${import.meta.env.VITE_VUE_APP_WebSite_HOST}/content/list/${columnId}/${previewId}`;
  }
  return ColumnType.单页面栏目 === columnType
    ? `${import.meta.env.VITE_VUE_APP_WebSite_HOST}/content/single/${columnId}`
    : `${import.meta.env.VITE_VUE_APP_WebSite_HOST}/content/list/${columnId}`;
};

/**
 * 获取素材页面url
 * @param {Number} columnId 栏目id
 * @param {Number} materialId 素材id
 * @param {Number} previewId 预览模板Id
 * @returns
 */
const getMaterialPageUrl = (columnId, materialId, previewId) => {
  if (previewId) {
    return `${import.meta.env.VITE_VUE_APP_WebSite_HOST}/content/detail/${columnId}/${materialId}/${previewId}`;
  }
  return `${import.meta.env.VITE_VUE_APP_WebSite_HOST}/content/detail/${columnId}/${materialId}`;
};

export { getColumnPageUrl, getMaterialPageUrl };
