<template>
  <div class="article-content" v-html="props.content"></div>
  <el-card
    v-if="props.attachmentFileList && props.attachmentFileList.length"
    class="attachments-card"
  >
    <zc-description label="附件" suffix="：">
      <el-row v-for="item in props.attachmentFileList" :key="item.id">
        <el-text>
          <a
            class="attachment-operation"
            target="_blank"
            :href="getDownloadUrl(item.id)"
          >
            {{ item.attachmentName }}</a
          >
        </el-text>
      </el-row>
    </zc-description>
  </el-card>
</template>

<script setup>
// #region 引用

// #endregion

// #region props/emit

const props = defineProps({
  content: String,
  attachmentFileList: Array,
});

// #endregion

// #region 变量/常量

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 获取下载地址
 */
const getDownloadUrl = (attachmentId) => {
  return `${import.meta.env.VUE_APP_API_HOST}/file/download/attachment?id=${
    attachmentId
  }`;
};

// #endregion
</script>

<style scoped>
.article-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>

<style scoped>
@media screen and (device-width >= 1180px) {
  .attachments-card {
    margin-top: 16px;
  }

  .attachment-operation {
    vertical-align: unset;
  }

  :deep(img) {
    display: inline-block;
    width: 100%;
  }
}
</style>

<style scoped>
@media screen and (device-width <= 1180px) {
  .attachments-card {
    min-height: 6vh;
    padding: 2vw 0;
    margin-top: 4vw;
    line-height: 2;
  }

  .attachment-operation {
    vertical-align: unset;
  }

  :deep(img) {
    display: inline-block;
    width: 100%;
  }

  :deep(p) {
    font-size: 4vw !important;
  }

  /* :deep(h1,h2,h3,h4,h5,h6) {
    font-size: 5vw !important;
  } */

  :deep(.el-text) {
    font-size: 4vw !important;
  }

  :deep(.zc-description-container .title) {
    font-size: 4vw !important;
  }
}
</style>
