<template>
  <component
    :is="dom[bundleComponent(props.componentData.componentCode)]"
    :key="new Date()"
    v-model="configDataForm"
    :component-data="props.componentData"
    :show-edit-content-button="props.showEditContentButton"
    :show-edit-components-button="props.showEditComponentsButton"
    :show-delete-components-button="props.showDeleteComponentsButton"
    :show-insert-components-button="props.showInsertComponentsButton"
    :show-sort-components-button="props.showSortComponentsButton"
    @handle-edit-components-content="handleEditComponentsContent"
    @handle-change-components="handleChangeComponents"
    @handle-delete-components="handleDeleteComponents"
    @handle-insert-components="handleInsertComponents"
    @handle-sort-components="handleSortComponents"
  ></component>
</template>
<script setup>
// #region 引用
import areaTitleOne from './AreaTitleOne.vue';
import areaTitleTwo from './AreaTitleTwo.vue';
// #endregion

// #region props/emit
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);
// #endregion

// #region 变量/常量

/**
 * 动态渲染组件
 */
const dom = shallowReactive({
  areaTitleOne,
  areaTitleTwo,
});

const configDataForm = ref({ title: '示例标题', description: '示例描述' });

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 绑定组件
 * @param {*} componentType
 */
const bundleComponent = (componentCode) => {
  let component = '';
  switch (componentCode) {
    case 'AREA_TITLE_1':
      component = 'areaTitleOne';
      break;
    case 'AREA_TITLE_2':
      component = 'areaTitleTwo';
      break;
    default:
      break;
  }
  return component;
};

/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // console.log('configDataForm--6:', configDataForm);
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('handle-edit-components-content', false);
    emits('update:modelValue', configDataForm.value);
  });
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  // console.log('handle-insert-components3');

  emits('handle-insert-components', true);
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};
// #endregion
</script>
