<template>
  <div v-if="props.copyrightNavComponent" class="index-template-bottom">
    <component
      :is="copyrightNav"
      :component-data="props.copyrightNavComponent"
      :show-edit-content-button="false"
      :show-edit-components-button="false"
      :show-delete-components-button="false"
      :show-insert-components-button="false"
      :show-sort-components-button="false"
    ></component>
  </div>
</template>
<script setup>
// #region 引用
import copyrightNav from '@/views/components/website/copyright/Copyright.vue';

// #endregion

// #region props/emit

const props = defineProps({
  copyrightNavComponent: {
    type: Object,
    default: null,
  },
});

// #endregion

// #region 变量/常量

/**
 * 底部导航组件
 */

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法
// #endregion
</script>
<style scoped>
.index-template-bottom {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
