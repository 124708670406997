<template>
  <div class="top-nav-form-container">
    <el-form
      ref="ruleFormRef"
      :model="configDataForm"
      :rules="rules"
      label-width="150px"
      class="account-form"
      :size="formSize"
    >
      <el-form-item label="左侧LOGO：" prop="logo">
        <zc-upload
          v-model="configDataForm.logo"
          :limit-single-size="1048576"
          type="singleImage"
          :image-thumbnail-width="140"
          :show-no-image="true"
          :small-text="true"
          :enable-popup="false"
          tip="说明：上传的图片大小不得超过1M，建议尺寸240*86px"
          @change="handleChange"
        >
          <template #singleImageError>
            <div class="el-image__error image-error-show">图片丢失</div>
          </template>
        </zc-upload>
      </el-form-item>
      <el-form-item
        v-if="componentData.fromType !== 2"
        label="栏目数据源："
        prop="columnTemplateTypeId"
      >
        <zc-select
          v-model="configDataForm.columnTemplateTypeId"
          :data="columnList"
        ></zc-select>
      </el-form-item>
    </el-form>
    <div class="top-nav-btn-container">
      <el-button @click="handleCancel()">取消</el-button>
      <el-button type="primary" @click="handleSave(ruleFormRef)"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script setup>
// #region 引用

// #endregion

// #region props/emit
const emits = defineEmits([
  'handle-show-edit-form',
  'update:modelValue',
  'handle-edit-components-content',
]);
const props = defineProps({
  componentData: {
    type: Object,
    default() {},
  },
});
// #endregion

// #region 变量/常量

// 栏目数据列表
const columnList = ref([{ value: '', label: '请选择栏目' }]);
const ruleFormRef = ref();
// 表单size
const formSize = ref('default');
// 表单绑定对象
const configDataForm = ref({
  leftLogoImageFileKey: 'leftLogoImage',
  leftLogoImageName: '示例图片.jpg',
  logo: null,
  columnTemplateTypeId: '',
});
// 表单规则
const rules = ref({
  logo: [{ required: true, message: '必填项', trigger: 'blur' }],
  columnTemplateTypeId: [
    { required: true, message: '必填项', trigger: 'blur' },
  ],
});

if (props.componentData.configData) {
  // 待数据更新完成后才执行
  nextTick(() => {
    configDataForm.value = JSON.parse(props.componentData.configData);
    configDataForm.value.logo = {
      name: configDataForm.value.leftLogoImageName,
      url: configDataForm.value.leftLogoImageFullUrl,
    };
    columnList.value = props.componentData.NavList;
  });
}

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法
/**
 * 图片改变后调用
 */
const handleChange = (file) => {
  ruleFormRef.value.validateField('logo', (valid) => {});
};

/**
 * 处理取消
 */
const handleCancel = () => {
  emits('handle-show-edit-form', false);
};
/**
 * 处理保存
 */
const handleSave = (form) => {
  if (!form) return;

  form.validate((valid, fields) => {
    if (valid) {
      // 待数据更新完成后才执行
      nextTick(() => {
        const curConfigDataForm = {
          leftLogoImageFileKey: 'leftLogoImage',
          leftLogoImageName: configDataForm.value.leftLogoImageName,
          logo: configDataForm.value.logo,
          columnTemplateTypeId: configDataForm.value.columnTemplateTypeId,
        };

        emits('handle-edit-components-content', false);
        emits('update:modelValue', curConfigDataForm);
      });
      // TODO 待完成保存逻辑
    }
  });
};
// #endregion
</script>

<style scoped>
.top-nav-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin: auto;
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

:deep(.el-form) {
  width: 100%;
  padding: 20px;
}

.top-nav-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: var(--el-color-white);
}

.top-nav-btn-container button {
  margin: 8px;
}
</style>
