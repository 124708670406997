import request from '@/apis/request-ssr';

const baseUrl = '/auth/web';
const auth = { tokenType: 'none' };

/**
 * 获取官网配置基本信息
 */
const getConfigBasicInfo = {
  url: `${baseUrl}/getConfigBasicInfo`,
  use(data) {
    return request.get(this.url, data, auth);
  },
};

/**
 * 获取官网配置完整信息
 */
const getConfigFullInfo = {
  url: `${baseUrl}/getConfigFullInfo`,
  use(data) {
    return request.get(this.url, data, auth);
  },
};

/**
 * 获取官网模板完整信息
 */
const getTemplateFullInfo = {
  url: `${baseUrl}/getTemplateFullInfo`,
  use(data) {
    return request.get(this.url, data, auth);
  },
};

/**
 * 获取栏目列表
 */
const getColumnList = {
  url: `${baseUrl}/getColumnList`,
  use(data) {
    return request.get(this.url, data, auth);
  },
};

/**
 * 获取栏目详情（通过栏目Id）
 */
const getColumnDetailById = {
  url: `${baseUrl}/getColumnDetailById`,
  use(data) {
    return request.get(this.url, data, auth);
  },
};

/**
 * 获取栏目的文章素材列表
 */
const getMaterialArticlesList = {
  url: `${baseUrl}/getMaterialArticlesList`,
  use(data) {
    return request.get(this.url, data, auth);
  },
};

/**
 * 获取栏目的文章素材详情
 */
const getMaterialArticlesDetail = {
  url: `${baseUrl}/getMaterialArticlesDetail`,
  use(data) {
    return request.get(this.url, data, auth);
  },
};

/**
 * 获取栏目附件素材列表
 */
const getMaterialAttachmentsList = {
  url: `${baseUrl}/getMaterialAttachmentsList`,
  use(data) {
    return request.get(this.url, data, auth);
  },
};

/**
 * 获取栏目的附件素材详情
 */
const getMaterialAttachmentsDetail = {
  url: `${baseUrl}/getMaterialAttachmentsDetail`,
  use(data) {
    return request.get(this.url, data, auth);
  },
};

/**
 * 获取栏目的图片素材详情
 */
const getMaterialImagesList = {
  url: `${baseUrl}/getMaterialImagesList`,
  use(data) {
    return request.get(this.url, data, auth);
  },
};

/**
 * 获取栏目的图片素材详情
 */
const getMaterialImagesDetail = {
  url: `${baseUrl}/getMaterialImagesDetail`,
  use(data) {
    return request.get(this.url, data, auth);
  },
};

/**
 * 获取栏目视频素材列表
 */
const getMaterialVideosList = {
  url: `${baseUrl}/getMaterialVideosList`,
  use(data) {
    return request.get(this.url, data, auth);
  },
};

/**
 * 获取栏目的视频素材详情
 */
const getMaterialVideosDetail = {
  url: `${baseUrl}/getMaterialVideosDetail`,
  use(data) {
    return request.get(this.url, data, auth);
  },
};

/**
 * 增加阅读次数
 */
const addReadCount = {
  url: `${baseUrl}/addReadCount`,
  use(data) {
    return request.post(this.url, data, auth);
  },
};

export {
  getConfigBasicInfo,
  getConfigFullInfo,
  getTemplateFullInfo,
  getColumnList,
  getColumnDetailById,
  getMaterialArticlesList,
  getMaterialArticlesDetail,
  getMaterialAttachmentsList,
  getMaterialAttachmentsDetail,
  getMaterialImagesList,
  getMaterialImagesDetail,
  getMaterialVideosList,
  getMaterialVideosDetail,
  addReadCount,
};
