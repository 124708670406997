<template>
  <div class="footer-three">
    <div
      class="footer-container"
      :style="{
        backgroundColor: configDataForm.backgroundColor,
        color: configDataForm.textColor,
      }"
      @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
      @mouseleave="handleShowOperationBtn(false)"
    >
      <div class="footer-container-logo">
        <span class="footer-container-logo-image">
          <img :src="configDataForm.logoImageFullUrl" fit="fill" />
        </span>
        <span class="footer-container-logo-title">
          {{ configDataForm.logoTitle }}
        </span>
        <span class="footer-container-logo-sub-title">
          {{ configDataForm.logoTitleDesc }}
        </span>
      </div>
      <div class="footer-container-connect">
        <span class="footer-container-connect-title">
          {{ configDataForm.title }}
        </span>
        <span v-for="item in configDataForm.items" :key="item">
          <span class="footer-container-connect-icon">
            <img :src="item.iconFullUrl" fit="fill" />
          </span>
          <span class="footer-container-connect-desc">
            {{ item.text }}
          </span>
        </span>
      </div>
      <div class="footer-container-qr-code">
        <div
          v-for="item in configDataForm.qrCodeItems"
          :key="item"
          class="footer-container-qr-code-item"
        >
          <span class="footer-container-qr-code-item-image">
            <img :src="item.iconFullUrl" fit="fill" />
          </span>
          <span class="footer-container-qr-code-item-desc">{{
            item.text
          }}</span>
        </div>
      </div>
      <client-only>
        <operation-container
          v-if="showOperationBtn && !showEditForm"
          :component-data="props.componentData"
          :show-edit-content-button="props.showEditContentButton"
          :show-edit-components-button="props.showEditComponentsButton"
          :show-delete-components-button="props.showDeleteComponentsButton"
          :show-insert-components-button="props.showInsertComponentsButton"
          :show-sort-components-button="props.showSortComponentsButton"
          @handle-show-edit-form="handleShowEditForm"
          @handle-change-components="handleChangeComponents"
          @handle-delete-components="handleDeleteComponents"
          @handle-insert-components="handleInsertComponents"
          @handle-sort-components="handleSortComponents"
        ></operation-container>
      </client-only>
    </div>

    <client-only>
      <el-drawer
        v-model="showEditForm"
        size="60%"
        :title="props.componentData.componentName"
      >
        <footer-edit-form
          v-if="showEditForm"
          v-model="configDataForm"
          :component-data="props.componentData"
          @handle-show-edit-form="handleShowEditForm"
          @handle-edit-components-content="handleEditComponentsContent"
        ></footer-edit-form>
      </el-drawer>
    </client-only>
  </div>
</template>
<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import OperationContainer from '../common/OperationContainer.vue';
import FooterEditForm from './FooterEditFormOne.vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

// console.log('props:',props);

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量
const configDataForm = ref({
  items: [
    {
      sortNum: 1,
      iconType: 1,
      iconName: '自定义上传图标1.jpg',
      iconFileKey: 'icon1',
      text: '地址：xxxxxx',
    },
    {
      sortNum: 2,
      iconType: 2,
      iconName: '系统图标1.jpg',
      iconFileKey: 'icon2',
      text: '电话：028-xxxxxx',
    },
  ],
  qrCodeItems: [
    {
      sortNum: 1,
      iconType: 1,
      iconName: '自定义上传图标1.jpg',
      iconFileKey: 'qrCode1',
      text: '地址：xxxxxx',
    },
    {
      sortNum: 2,
      iconType: 1,
      iconName: '系统图标1.jpg',
      iconFileKey: 'qrCode2',
      text: '电话：028-xxxxxx',
    },
  ],
  backgroundImageName: '示例图片.jpg',
  backgroundImageFileKey: 'backGroundImage',
  textColor: '#ffffff',
  backgroundColor: '#131313',
});

if (props.componentData.configData) {
  // 服务器渲染的直接调用
  configDataForm.value = JSON.parse(props.componentData.configData);
  configDataForm.value.items.sort((a, b) => {
    return a.sortNum - b.sortNum;
  });
  configDataForm.value.qrCodeItems.sort((a, b) => {
    return a.sortNum - b.sortNum;
  });
  // 后台编辑的处理一下
  // nextTick(() => {
  //   configDataForm.value = JSON.parse(props.componentData.configData);
  //   configDataForm.value.items.sort((a, b) => {
  //     return a.sortNum - b.sortNum;
  //   });
  //   configDataForm.value.qrCodeItems.sort((a, b) => {
  //     return a.sortNum - b.sortNum;
  //   });
  // });
}

// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法
/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};

/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};

/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // console.log('configDataForm--1-FooterThree:', configDataForm);
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};

// #endregion
</script>
<style scoped>
.footer-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 400px;
  margin: auto;
}

.footer-container-logo {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
  font-style: normal;
  font-weight: 400;
}

.footer-container-logo-image {
  height: 124px;
  border-bottom: 1px solid #fff;
}

.footer-container-logo-title {
  height: 60px;
  line-height: 60px;
  letter-spacing: 16px;
}

.footer-container-connect {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.footer-container-connect > span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer-container-connect-title {
  display: inline-block;
  height: 168px;
  font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.footer-container-connect-icon {
  width: 40px;
  height: 40px;
}

.footer-container-connect-icon img {
  width: 24px;
  height: 24px;
}

.footer-container-connect-desc {
  height: 40px;
  font-size: 14px;
  line-height: 2;
  color: #383838;
}

.footer-container-qr-code {
  /* width: 300px; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.footer-container-qr-code-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 210px;
  height: 280px;
}

.footer-container-qr-code-item-image {
  width: 171px;
  height: 171px;
}

.footer-container-qr-code-item-image img {
  width: 100%;
  height: 100%;
}

.footer-container-qr-code-item-desc {
  height: 20px;
  font-size: 16px;

  /* color: #353535; */
  text-align: center;
}
</style>
