export enum WebsiteStatus {
  草稿 = 0, // 草稿
  已发布 = 1, // 已发布
  已停发 = 2, // 已停发
}

export enum ColumnType {
  导航栏目 = 1, // 导航栏目
  链接栏目 = 2, // 链接栏目
  单页面栏目 = 3, // 单页面栏目
  文章列表栏目 = 4, // 文章列表栏目
  图片列表栏目 = 5, // 图片列表栏目
  视频列表栏目 = 6, // 视频列表栏目
  附件列表栏目 = 8, // 附件列表栏目
  自定义模板页栏目 = 20, // 自定义模板页栏目
}

export enum OwTerminal {
  Web端 = 1, // WEB端
  移动端 = 2, // 移动端
}

export enum OwTemplateStatus {
  待发布 = 1, // 待发布
  已发布 = 2, // 已发布
}

export enum OwPageType {
  首页 = 1, // 首页
  单页面 = 2, // 单页面
  文章列表页 = 3, // 文章列表页
  文章详情页 = 4, // 文章详情页
  图片列表页 = 5, // 图片列表页
  附件列表页 = 6, // 附件列表页
  附件详情页 = 7, // 附件详情页
  视频列表页 = 8, // 视频列表页
  视频播放页 = 9, // 视频播放页
  音频列表页 = 10, // 音频列表页
  音频播放页 = 11, // 音频播放页
  自定义页面 = 20, // 自定义页面
}

export enum OwComponentType {
  顶部导航栏组件 = 1, // 顶部导航栏组件
  轮播图组件 = 2, // 轮播图组件
  区域标题组件 = 3, // 区域标题组件
  栏目内容组件 = 4, // 栏目内容组件
  图文组合组件 = 5, // 图文组合组件
  图文组合集组件 = 6, // 图文组合集组件
  独立块状组件 = 7, // 独立块状组件
  友情链接组件 = 8, // 友情链接组件
  底部导航区组件 = 9, // 底部导航区组件
  版权区组件 = 10, // 版权区组件
}
