<template>
  <div class="footer-form-container">
    <el-form
      ref="ruleFormRef"
      :model="configDataForm"
      :rules="rules"
      label-width="150px"
      class="account-form"
      :size="formSize"
    >
      <div class="card-frame other-card-frame">
        <div class="card-frame-top">基本配置</div>
        <div class="card-frame-container">
          <el-form-item label="背景图片：" prop="backGroundImageObj">
            <zc-upload
              v-model="configDataForm.backGroundImageObj"
              :limit-single-size="1048576"
              :image-thumbnail-width="240"
              type="singleImage"
              :show-no-image="true"
              :small-text="true"
              :enable-popup="false"
              tip="说明：上传的图片大小不得超过1M，建议尺寸1920*1080px"
              @change="handleBackGroundImageChange(0)"
            >
              <template #singleImageError>
                <div class="el-image__error image-error-show">图片丢失</div>
              </template>
            </zc-upload>
          </el-form-item>
          <el-form-item label="字体颜色：" prop="textColor">
            <el-color-picker v-model="configDataForm.textColor" />
          </el-form-item>
        </div>
      </div>
      <div class="card-frame other-card-frame">
        <div class="card-frame-top">左侧联系方式区</div>
        <div class="card-frame-container">
          <el-row
            v-for="(item, index) in configDataForm.items"
            :key="item"
            class="el-row-customer"
          >
            <span class="copyright-form-item-form">
              <el-col :span="24">
                <el-form-item
                  label="图标："
                  :prop="`items[${index}].iconNameObj`"
                  :rules="rules.iconNameObj"
                >
                  <zc-upload
                    v-model="item.iconNameObj"
                    :limit-single-size="1048576"
                    type="singleImage"
                    :show-no-image="true"
                    :small-text="true"
                    :enable-popup="false"
                    tip="说明：上传的图片大小不得超过100KB，建议尺寸40*40px"
                    @change="handleChange(index)"
                  >
                    <template #singleImageError>
                      <div class="el-image__error image-error-show">
                        图片丢失
                      </div>
                    </template>
                  </zc-upload>
                </el-form-item>
                <el-form-item
                  :label="'文案' + item.sortNum + '：'"
                  :prop="`items[${index}].text`"
                  :rules="rules.text"
                >
                  <el-input
                    v-model="item.text"
                    style="width: 300px !important"
                  />
                </el-form-item>
              </el-col>
            </span>
            <span class="copyright-form-item-remove">
              <template v-if="configDataForm.items.length > 1">
                <el-link type="danger" @click="handleDel(index)">
                  移除
                </el-link>
              </template>
              <template v-else>
                <el-tooltip content="至少保留一项版权内容项" placement="top">
                  <el-link type="danger" disabled> 移除 </el-link>
                </el-tooltip>
              </template>
            </span>
          </el-row>
          <el-row>
            <el-col :span="24">
              <template v-if="configDataForm.items.length < 4">
                <el-button style="width: 100%" @click="handleAddItem()">
                  <el-icon><Plus /></el-icon>添加项</el-button
                >
              </template>
              <template v-else>
                <el-tooltip content="最多添加四项" placement="top">
                  <el-button style="width: 100%" disabled>
                    <el-icon><Plus /></el-icon>添加项</el-button
                  >
                </el-tooltip>
              </template>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="card-frame other-card-frame">
        <div class="card-frame-top">右侧二维码区</div>
        <div class="card-frame-container">
          <el-row
            v-for="(item, index) in configDataForm.qrCodeItems"
            :key="item"
            class="el-row-customer"
          >
            <span class="copyright-form-item-form">
              <el-col :span="24">
                <el-form-item
                  label="二维码："
                  :prop="`qrCodeItems[${index}].iconNameObj`"
                  :rules="rules.iconNameObj"
                >
                  <zc-upload
                    v-model="item.iconNameObj"
                    :limit-single-size="1048576"
                    type="singleImage"
                    :show-no-image="true"
                    :small-text="true"
                    :enable-popup="false"
                    tip="说明：上传的图片大小不得超过100KB，建议尺寸40*40px"
                    @change="handleQrCodeChange(index)"
                  >
                    <template #singleImageError>
                      <div class="el-image__error image-error-show">
                        图片丢失
                      </div>
                    </template>
                  </zc-upload>
                </el-form-item>
                <el-form-item
                  :label="'文案' + item.sortNum + '：'"
                  :prop="`qrCodeItems[${index}].text`"
                  :rules="rules.text"
                >
                  <el-input
                    v-model="item.text"
                    style="width: 300px !important"
                  />
                </el-form-item>
              </el-col>
            </span>
            <span class="copyright-form-item-remove">
              <template v-if="configDataForm.qrCodeItems.length > 1">
                <el-link type="danger" @click="handleQrCodeDel(index)">
                  移除
                </el-link>
              </template>
              <template v-else>
                <el-tooltip content="至少保留一项版权内容项" placement="top">
                  <el-link type="danger" disabled> 移除 </el-link>
                </el-tooltip>
              </template>
            </span>
          </el-row>
          <el-row>
            <el-col :span="24">
              <template v-if="configDataForm.qrCodeItems.length < 6">
                <el-button style="width: 100%" @click="handleAddQrCodeItem()">
                  <el-icon><Plus /></el-icon>添加项</el-button
                >
              </template>
              <template v-else>
                <el-tooltip content="最多添加六项" placement="top">
                  <el-button style="width: 100%" disabled>
                    <el-icon><Plus /></el-icon>添加项</el-button
                  >
                </el-tooltip>
              </template>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
    <div class="footer-btn-container">
      <el-button @click="handleCancel()">取消</el-button>
      <el-button type="primary" @click="handleSave(ruleFormRef)"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script setup>
// #region 引用
import {
  verifyCharacterLength,
  verifyLinkUrl,
} from '@/utils/common/form-verify-util';
import { Plus } from '@element-plus/icons-vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  componentData: {
    type: Object,
    default() {},
  },
});
/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'update:modelValue',
  'handle-edit-components-content',
]);

// #endregion

// #region 变量/常量

// 栏目数据列表

// 表单size
const formSize = ref('default');
const ruleFormRef = ref();

// 表单绑定对象
const configDataForm = ref({
  items: [
    {
      sortNum: 1,
      iconType: 1,
      iconName: '自定义上传图标1.jpg',
      iconFileKey: 'icon1',
      text: '地址：xxxxxx',
    },
    {
      sortNum: 2,
      iconType: 1,
      iconName: '系统图标1.jpg',
      iconFileKey: 'icon2',
      text: '电话：028-xxxxxx',
    },
  ],
  qrCodeItems: [
    {
      sortNum: 1,
      iconType: 1,
      iconName: '自定义上传图标1.jpg',
      iconFileKey: 'qrCode1',
      text: '地址：xxxxxx',
    },
    {
      sortNum: 2,
      iconType: 1,
      iconName: '系统图标1.jpg',
      iconFileKey: 'qrCode2',
      text: '电话：028-xxxxxx',
    },
  ],
  logoImageObj: null,
  logoImageName: '示例图片.jpg',
  logoImageFileKey: 'logoImage',
  backGroundImageObj: null,
  backgroundImageName: '示例图片.jpg',
  backgroundImageFileKey: 'backGroundImage',
  textColor: '#666666',
  backgroundColor: '#ffffff',
});

if (props.componentData.configData) {
  configDataForm.value = JSON.parse(props.componentData.configData);
  console.log('props:', props);

  configDataForm.value.items = configDataForm.value.items.map((f) => ({
    ...f,
    iconNameObj: {
      name: f.iconName,
      url: f.iconFullUrl,
    },
  }));

  configDataForm.value.qrCodeItems = configDataForm.value.qrCodeItems.map(
    (f) => ({
      ...f,
      iconNameObj: {
        name: f.iconName,
        url: f.iconFullUrl,
      },
    }),
  );

  configDataForm.value.backGroundImageObj = {
    name: configDataForm.value.backgroundImageName,
    url: configDataForm.value.backgroundImageFullUrl,
  };
}
// 表单规则
const rules = ref({
  backGroundImageObj: [{ required: true, message: '必填项', trigger: 'blur' }],
  iconNameObj: [{ required: true, message: '必填项', trigger: 'blur' }],
  textColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  text: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 50, trigger: 'blur' },
  ],
});
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 图标图片改变后调用
 */
const handleChange = (index) => {
  // console.log('index:', index);
  ruleFormRef.value.validateField(`items[${index}].iconNameObj`, (valid) => {});
};

/**
 * 二维码改变后调用
 */
const handleQrCodeChange = (index) => {
  // console.log('index:', index);
  ruleFormRef.value.validateField(
    `qrCodeItems[${index}].iconNameObj`,
    (valid) => {},
  );
};

/**
 * 右侧图片改变后调用
 */
const handleBackGroundImageChange = (index) => {
  // console.log('index:', index);
  ruleFormRef.value.validateField('backGroundImageObj', (valid) => {});
};

/**
 * 处理取消
 */
const handleCancel = () => {
  emits('handle-show-edit-form', false);
};
/**
 * 处理保存
 */
const handleSave = (form) => {
  if (!form) return;

  form.validate((valid, fields) => {
    if (valid) {
      // console.log('configDataForm-FooterEditForm:', configDataForm.value);

      // 待数据更新完成后才执行
      nextTick(() => {
        emits('handle-edit-components-content', false);
        emits('update:modelValue', configDataForm.value);
      });
      // TODO 待完成保存逻辑
    } else {
      console.log('fields:', fields);
    }
  });
};

/**
 * 处理删除（图标）
 */
const handleDel = (index) => {
  configDataForm.value.items.splice(index, 1);
  //   handleResetRules();
};

/**
 * 处理删除（二维码）
 */
const handleQrCodeDel = (index) => {
  configDataForm.value.qrCodeItems.splice(index, 1);
  //   handleResetRules();
};

/**
 * 添加项目
 */
const handleAddItem = () => {
  // 获取当前最大排序号

  const sortNumArr = configDataForm.value.items.map((f) => {
    return f.sortNum;
  });

  const sortNum = Math.max(...sortNumArr) + 1;

  configDataForm.value.items.push({
    sortNum,
    iconType: 1,
    iconName: '',
    iconFileKey: `icon${sortNum}`,
    text: '',
    iconNameObj: null,
  });
};

/**
 * 添加项目（二维码）
 */
const handleAddQrCodeItem = () => {
  // 获取当前最大排序号

  const sortNumArr = configDataForm.value.qrCodeItems.map((f) => {
    return f.sortNum;
  });

  const sortNum = Math.max(...sortNumArr) + 1;

  configDataForm.value.qrCodeItems.push({
    sortNum,
    iconType: 1,
    iconName: '',
    iconFileKey: `qrCode${sortNum}`,
    text: '',
    iconNameObj: null,
  });
};

// #endregion
</script>

<style scoped>
.footer-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin: auto;
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.footer-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: var(--el-color-white);
}

.footer-btn-container button {
  margin: 8px;
}

:deep(.el-form) {
  width: 100%;
  padding: 20px;
}

.card-frame {
  margin-bottom: unset !important;
}

.el-row-customer {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  padding-top: 10px;
  margin: 10px 0;
  border: 1px solid;
  border-color: var(--el-color-grey-light-45);

  /* flex-direction: column; */
}

.copyright-form-item-form {
  width: calc(100% - 44px) !important;
}

.copyright-form-item-remove {
  width: 24px !important;
  margin: 0 5px;
}

:deep(.el-color-picker__trigger) {
  width: 100px;
}
</style>
