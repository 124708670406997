<template>
  <div class="list-with-image" v-for="(item, index) in props.contentList">
    <a :href="item.url">
      <div class="list-with-image-content">
        <div class="list-with-image-content-left">
          <div class="list-with-image-content-left-image">
            <img :src="item.coverImageUrl" />
          </div>
          <div class="list-with-image-content-left-tag">
            <span class="list-with-image-content-left-tag-time">
              <span class="list-with-image-content-left-tag-time-dy">
                {{ new Date(item.publishTime).getDate() }}
              </span>
              <span class="list-with-image-content-left-tag-time-ny">
                {{ new Date(item.publishTime).getFullYear() + '.' + (new Date(item.publishTime).getMonth() + 1) }}
              </span>
            </span>
            <span class="list-with-image-content-left-tag-read">
              <span class="list-with-image-content-left-tag-read-eye"></span>
              <span class="list-with-image-content-left-tag-read-count">
                {{ item.readCount }}
              </span>
            </span>
          </div>
        </div>
        <div class="list-with-image-content-right">
          <div class="list-with-image-content-right-title">{{ item.title }}</div>
          <div class="list-with-image-content-right-digest">{{ item.digest }}</div>
          <div class="list-with-image-content-right-btn">
            <span> 详细+ </span>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>
<script setup>
//#region 引用

//#endregion

//#region props/emit
const props = defineProps({
  contentList: {
    type: Array,
    default: [],
  },
});
//#endregion

//#region 变量/常量

//#endregion

//#region 生命周期

//#endregion

//#region 业务方法

const handleClick = () => {
  alert('1222');
};

//#endregion
</script>
<style scoped>
@media screen and (min-device-width: 1180px) {
  .list-with-image {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #cccccc;
  }

  .list-with-image-title {
    color: #008bd5;
    font-size: 1.25rem;
    display: block;
    margin: 20px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.5s ease;
  }

  .list-with-image-content {
    margin: 10px 0;
    display: flex;
    /* flex-direction: column; */
  }

  .list-with-image-content-left {
    width: 300px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .list-with-image-content-left-image {
    width: 288px;
    height: 192px;
    transition: all 0.5s ease;
  }

  .list-with-image-content-left-image img {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }
  .list-with-image:hover {
    border-bottom: 1px solid #cccccc;
  }

  .list-with-image:hover .list-with-image-content-left-image img,
  .list-with-image:hover .list-with-image-content-right-title {
    transform: translateX(5px);
  }

  .list-with-image-content-left-tag {
    width: 70px;
    position: absolute;
    top: 0;
    left: 5%;
    text-align: center;
    background: #008bd5;
    opacity: 0.9;
    color: white;
  }

  .list-with-image-content-left-tag span {
    display: inline-block;
  }
  .list-with-image-content-left-tag-time {
    display: flex;
    /* flex-direction: column; */
  }

  .list-with-image-content-left-tag-time-dy {
    font-size: 1.4375rem;
    color: #ffffff;
  }

  .list-with-image-content-left-tag-time-ny {
    width: 100%;
  }

  .list-with-image-content-left-tag-read {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ffffff;
    padding: 3px 0;
    font-size: 0.75rem;
    color: #888;
  }

  .list-with-image-content-left-tag-read-eye {
    display: inline-block;
    width: 17px;
    height: 11px;
    margin-right: 3px;
    background: url(./image/eye.png) no-repeat;
    background-size: 100% 100%;
  }

  .list-with-image-content-right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    /* justify-content: space-between; */
  }

  .list-with-image-content-right-digest {
    font-size: 0.875rem;
    color: #8c8c8c;
    line-height: 22px;
    height: 66px;
    overflow: hidden;
  }

  .list-with-image-content-right-title {
    line-height: 40px;
    color: #008bd5;
    font-size: 1.25rem;
    text-overflow: ellipsis;
    transition: all 0.5s ease;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .list-with-image-content-right-btn {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .list-with-image-content-right-btn span {
    display: inline-block;
    width: 110px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 0.875rem;
    color: #8c8c8c;
    background: #ffffff;
    box-shadow: 0 0 10px 0 #bbb9b9;
    margin: 40px 10px 0 0;
  }

  .list-with-image:hover .list-with-image-content-right-btn span {
    background: #008bd5;
    color: #ffffff;
  }
}
</style>

<style scoped>
@media screen and (max-device-width: 1180px){

  .list-with-image {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #cccccc;
  }


  .list-with-image-content {
    margin: 2.5vw;
    display: flex;
    flex-direction: column;
  }

  .list-with-image-content-left {
    width: 100%;
    height: 60vw;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .list-with-image-content-left-image {
    width: 100%;
    height: 60vw;
    transition: all 0.5s ease;
  }

  .list-with-image-content-left-image img {
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }
  .list-with-image:hover {
    border-bottom: 1px solid #cccccc;
  }

  .list-with-image:hover .list-with-image-content-left-image img,
  .list-with-image:hover .list-with-image-content-right-title {
    transform: translateX(5px);
  }

  .list-with-image-content-left-tag {
    width: 17.5vw;
    position: absolute;
    top: 0;
    left: 5%;
    text-align: center;
    background: #008bd5;
    opacity: 0.9;
    color: white;
  }

  .list-with-image-content-left-tag span {
    display: inline-block;
  }
  .list-with-image-content-left-tag-time {
    display: flex;
    /* flex-direction: column; */
  }

  .list-with-image-content-left-tag-time-dy {
    font-size: 10vw;
    color: #ffffff;
  }

  .list-with-image-content-left-tag-time-ny {
    width: 100%;
    font-size: 4vw;
  }

  .list-with-image-content-left-tag-read {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ffffff;
    padding: 1vw 0;
    font-size: 4vw;
    color: #888;
  }

  .list-with-image-content-left-tag-read-eye {
    display: inline-block;
    width: 4vw;
    height: 2.5vw;
    margin-right: 1vw;
    background: url(./image/eye.png) no-repeat;
    background-size: 100% 100%;
  }

  .list-with-image-content-right {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-left: 1vw;
    /* justify-content: space-between; */
  }

  .list-with-image-content-right-digest {
    font-size: 4vw;
    color: #8c8c8c;
    line-height: 2;
    height: 16vw;
    overflow: hidden;
  }

  .list-with-image-content-right-title {
    line-height: 2;
    color: #008bd5;
    font-size: 5vw;
    text-overflow: ellipsis;
    transition: all 0.5s ease;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .list-with-image-content-right-btn {
    display: flex;
    width: 100vw;
    justify-content: flex-end;
    padding-right: 2vw;
  }

  .list-with-image-content-right-btn span {
    display: inline-block;
    width: 25vw;
    height: 10vw;
    line-height: 2;
    text-align: center;
    font-size: 5vw;
    color: #8c8c8c;
    background: #ffffff;
    box-shadow: 0 0 2.5vw 0 #bbb9b9;
    margin: 10vw 2.5vw 0 0;
  }

  .list-with-image:hover .list-with-image-content-right-btn span {
    background: #008bd5;
    color: #ffffff;
  }
}
</style>

