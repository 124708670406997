<template>
  <div class="area-title-form-container">
    <el-form
      ref="ruleFormRef"
      :model="configDataForm"
      :rules="rules"
      label-width="180px"
      :size="formSize"
    >
      <el-form-item label="标题：" prop="title">
        <el-input v-model="configDataForm.title" />
      </el-form-item>
      <el-form-item label="描述：" prop="description">
        <el-input v-model="configDataForm.description" />
      </el-form-item>
      <el-form-item label="区域背景色：" prop="backgroundColor">
        <el-color-picker v-model="configDataForm.backgroundColor" />
      </el-form-item>
    </el-form>
    <div class="area-title-btn-container">
      <el-button @click="handleCancel()">取消</el-button>
      <el-button type="primary" @click="handleSave(ruleFormRef)"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script setup>
// #region 引用
import { verifyCharacterLength } from '@/utils/common/form-verify-util';

// #endregion

// #region props/emit

/**
 * 属性
 */
const props = defineProps({
  componentData: {
    type: Object,
    default() {},
  },
});
/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'update:modelValue',
  'handle-edit-components-content',
]);

// #endregion

// #region 变量/常量

// 栏目数据列表
// 表单size
const formSize = ref('default');
const ruleFormRef = ref();

// 表单绑定对象
const configDataForm = ref({
  title: '示例中文标题',
  description: '实例英文标题',
  backgroundColor: '#fff',
});
if (props.componentData.configData) {
  configDataForm.value = JSON.parse(props.componentData.configData);
}
// 表单规则
const rules = ref({
  backgroundColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  title: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 50, trigger: 'blur' },
  ],
  description: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 200, trigger: 'blur' },
  ],
});
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 处理取消
 */
const handleCancel = () => {
  emits('handle-show-edit-form', false);
};
/**
 * 处理保存
 */
const handleSave = (form) => {
  if (!form) return;

  form.validate((valid, fields) => {
    if (valid) {
      // 待数据更新完成后才执行
      nextTick(() => {
        emits('handle-edit-components-content', false);
        emits('update:modelValue', configDataForm.value);
      });
    }
  });
};
// #endregion
</script>

<style scoped>
.area-title-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 20px 0;
  margin: 20% 0;
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

  /* border: 1px solid; */

  /* border-color: var(--el-theme-color-transparent-6); */
}

.area-title-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: var(--el-color-white);
}

.area-title-btn-container button {
  margin: 8px;
}

:deep(.el-color-picker__trigger) {
  width: 100px;
}

:deep(.el-form) {
  width: 100%;
  padding: 20px;
}
</style>
