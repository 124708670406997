<template>
  <div class="top-nav-form-container">
    <el-form
      ref="ruleFormRef"
      :model="configDataForm"
      :rules="rules"
      label-width="150px"
      class="account-form"
      :size="formSize"
    >
      <el-form-item label="顶部LOGO：" prop="top">
        <zc-upload
          v-model="configDataForm.top"
          :limit-single-size="1048576"
          type="singleImage"
          :image-thumbnail-width="140"
          :show-no-image="true"
          :small-text="true"
          :enable-popup="false"
          tip="说明：上传的图片大小不得超过1M，建议尺寸240*86px"
          @change="handleTopChange"
        >
          <template #singleImageError>
            <div class="el-image__error image-error-show">图片丢失</div>
          </template>
        </zc-upload>
      </el-form-item>
      <el-form-item label="左侧LOGO：" prop="logo">
        <zc-upload
          v-model="configDataForm.logo"
          :limit-single-size="1048576"
          type="singleImage"
          :image-thumbnail-width="140"
          :show-no-image="true"
          :small-text="true"
          :enable-popup="false"
          tip="说明：上传的图片大小不得超过1M，建议尺寸240*86px"
          @change="handleLeftChange"
        >
          <template #singleImageError>
            <div class="el-image__error image-error-show">图片丢失</div>
          </template>
        </zc-upload>
      </el-form-item>
      <el-form-item label="左侧深色LOGO：" prop="logoDeep">
        <zc-upload
          v-model="configDataForm.logoDeep"
          :limit-single-size="1048576"
          type="singleImage"
          :image-thumbnail-width="140"
          :show-no-image="true"
          :small-text="true"
          :enable-popup="false"
          tip="说明：上传的图片大小不得超过1M，建议尺寸240*86px"
          @change="handleLeftDeepChange"
        >
          <template #singleImageError>
            <div class="el-image__error image-error-show">图片丢失</div>
          </template>
        </zc-upload>
      </el-form-item>
      <el-form-item
        v-if="componentData.fromType !== 2"
        label="栏目数据源："
        prop="columnTemplateTypeId"
      >
        <zc-select
          v-model="configDataForm.columnTemplateTypeId"
          :data="columnList"
        ></zc-select>
      </el-form-item>
      <el-form-item label="顶部背景色：" prop="topBackgroundColor">
        <el-color-picker v-model="configDataForm.topBackgroundColor" />
      </el-form-item>
      <el-form-item label="登录文案：" prop="loginTextTitle">
        <el-input v-model="configDataForm.loginTextTitle" />
      </el-form-item>
      <el-form-item label="登录文案颜色：" prop="loginTextColor">
        <el-color-picker v-model="configDataForm.loginTextColor" />
      </el-form-item>
      <el-form-item label="登录跳转链接：" prop="loginLinkUrl">
        <el-input v-model="configDataForm.loginLinkUrl" />
      </el-form-item>
      <el-form-item label="搜索文案：" prop="searchTextTitle">
        <el-input v-model="configDataForm.searchTextTitle" />
      </el-form-item>
      <el-form-item label="搜索文案颜色：" prop="searchTextColor">
        <el-color-picker v-model="configDataForm.searchTextColor" />
      </el-form-item>
      <el-form-item label="搜索文案链接：" prop="searchLinkUrl">
        <el-input v-model="configDataForm.searchLinkUrl" />
      </el-form-item>
    </el-form>
    <div class="top-nav-btn-container">
      <el-button @click="handleCancel()">取消</el-button>
      <el-button type="primary" @click="handleSave(ruleFormRef)"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script setup>
// #region 引用
import {
  verifyCharacterLength,
  verifyLinkUrl,
} from '@/utils/common/form-verify-util';
// #endregion

// #region props/emit
const emits = defineEmits([
  'handle-show-edit-form',
  'update:modelValue',
  'handle-edit-components-content',
]);
const props = defineProps({
  componentData: {
    type: Object,
    default() {},
  },
});
// #endregion

// #region 变量/常量

// 栏目数据列表
const columnList = ref([{ value: '', label: '请选择栏目' }]);
const ruleFormRef = ref();
// 表单size
const formSize = ref('default');
// 表单绑定对象
const configDataForm = ref({
  leftLogoImageFileKey: 'leftLogoImage',
  leftLogoImageName: '示例图片.jpg',
  logo: null,
  leftDeepLogoImageFileKey: 'leftDeepLogoImage',
  leftDeepLogoImageName: '图标2.png',
  logoDeep: null,
  topImageFileKey: 'topImage',
  topImageName: '图标2.png',
  top: null,
  topBackgroundColor: '#008bd5',
  loginTextTitle: '登录融合门户',
  loginTextColor: '#008bd5',
  loginLinkUrl: '/article-list/132',
  searchTextTitle: '搜索',
  searchTextColor: '#008bd5',
  searchLinkUrl: '/article-list/132',
  columnTemplateTypeId: '',
});
// 表单规则
const rules = ref({
  logo: [{ required: true, message: '必填项', trigger: 'blur' }],
  logoDeep: [{ required: true, message: '必填项', trigger: 'blur' }],
  top: [{ required: true, message: '必填项', trigger: 'blur' }],
  topBackgroundColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  loginTextTitle: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 10, trigger: 'blur' },
  ],
  loginTextColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  loginLinkUrl: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyLinkUrl, clen: 500, trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 500, trigger: 'blur' },
  ],
  searchTextTitle: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 4, trigger: 'blur' },
  ],
  searchTextColor: [{ required: true, message: '必填项', trigger: 'blur' }],
  searchLinkUrl: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyLinkUrl, clen: 500, trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 500, trigger: 'blur' },
  ],
  columnTemplateTypeId: [
    { required: true, message: '必填项', trigger: 'blur' },
  ],
});

if (props.componentData.configData) {
  // 待数据更新完成后才执行
  nextTick(() => {
    configDataForm.value = JSON.parse(props.componentData.configData);
    configDataForm.value.logo = {
      name: configDataForm.value.leftLogoImageName,
      url: configDataForm.value.leftLogoImageFullUrl,
    };
    configDataForm.value.logoDeep = {
      name: configDataForm.value.leftDeepLogoImageName,
      url: configDataForm.value.leftDeepLogoImageFullUrl,
    };
    configDataForm.value.top = {
      name: configDataForm.value.topImageName,
      url: configDataForm.value.topImageFullUrl,
    };
    columnList.value = props.componentData.NavList;
  });
}

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法
/**
 * 图片改变后调用
 */
const handleTopChange = (file) => {
  ruleFormRef.value.validateField('top', (valid) => {});
};

/**
 * 图片改变后调用
 */
const handleLeftChange = (file) => {
  ruleFormRef.value.validateField('logo', (valid) => {});
};

/**
 * 图片改变后调用
 */
const handleLeftDeepChange = (file) => {
  ruleFormRef.value.validateField('logoDeep', (valid) => {});
};

/**
 * 处理取消
 */
const handleCancel = () => {
  emits('handle-show-edit-form', false);
};
/**
 * 处理保存
 */
const handleSave = (form) => {
  if (!form) return;

  form.validate((valid, fields) => {
    if (valid) {
      // 待数据更新完成后才执行
      nextTick(() => {
        const curConfigDataForm = {
          leftLogoImageFileKey: 'leftLogoImage',
          leftLogoImageName: configDataForm.value.leftLogoImageName,
          logo: configDataForm.value.logo,
          leftDeepLogoImageFileKey: 'leftDeepLogoImage',
          leftDeepLogoImageName: configDataForm.value.leftDeepLogoImageName,
          logoDeep: configDataForm.value.logoDeep,
          topImageFileKey: 'topImage',
          topImageName: configDataForm.value.topImageName,
          top: configDataForm.value.top,
          columnTemplateTypeId: configDataForm.value.columnTemplateTypeId,
          topBackgroundColor: configDataForm.value.topBackgroundColor,
          loginTextTitle: configDataForm.value.loginTextTitle,
          loginTextColor: configDataForm.value.loginTextColor,
          loginLinkUrl: configDataForm.value.loginLinkUrl,
          searchTextTitle: configDataForm.value.searchTextTitle,
          searchTextColor: configDataForm.value.searchTextColor,
          searchLinkUrl: configDataForm.value.searchLinkUrl,
        };
        emits('update:modelValue', curConfigDataForm);
        emits('handle-edit-components-content', false);
      });
      // TODO 待完成保存逻辑
    }
  });
};
// #endregion
</script>

<style scoped>
.top-nav-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin: auto;
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

:deep(.el-form) {
  width: 100%;
  padding: 20px;
}

.top-nav-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: var(--el-color-white);
}

.top-nav-btn-container button {
  margin: 8px;
}
</style>
