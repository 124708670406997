<template>
  <div class="location">
    <span>当前位置：</span>
    <span v-for="(item, index) in props.contentNavList" :key="index">
      <a :href="item.url">{{ item.title }}</a>
    </span>
  </div>
</template>
<script setup>
// #region 引用

// #endregion

// #region props/emit
const props = defineProps({
  contentNavList: {
    type: Array,
    default: () => [],
  },
});
// #endregion

// #region 变量/常量

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

// #endregion
</script>
<style scoped>
@media screen and (device-width >= 1180px) {
  .location {
    width: 1200px;
    height: 50px;
    margin: 0 auto;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 60px;
  }

  .location span a {
    color: #000;
    text-decoration: none;
  }

  .location span:first-of-type::before {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 2px;
    content: '';
    background: url('./image/location.png');
    background-position-y: 1px;
  }

  .location span:not(:first-of-type, :last-of-type)::after {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-left: 2px;
    content: '';
    background: url('./image/next.png');
    background-position-y: 2px;
  }
}
</style>

<style scoped>
@media screen and (device-width <= 1180px) {
  .location {
    height: 12.5vw;
    padding: 2vw;
    margin: 0 auto;
    margin-bottom: 1vw;
    font-size: 4vw;
    line-height: 2;
  }

  .location span a {
    color: #000;
    text-decoration: none;
  }

  .location span:first-of-type::before {
    display: inline-block;
    width: 4vw;
    height: 4vw;
    margin-right: 0.5vw;
    content: '';
    background: url('./image/location.png');
    background-position-y: 0.1vw;
    background-size: 100% 100%;
  }

  .location span:not(:first-of-type, :last-of-type)::after {
    display: inline-block;
    width: 4vw;
    height: 4vw;
    margin: 0 0.5vw;
    content: '';
    background: url('./image/next.png');
    background-position-y: 0.5vw;
    background-size: 100% 100%;
  }
}
</style>
