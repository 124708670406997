<template>
  <div class="operation-container">
    <el-button
      v-if="props.showEditContentButton"
      @click="handleShowEditForm(true)"
      >编辑内容</el-button
    >
    <el-button
      v-if="props.showEditComponentsButton"
      @click="handleChangeComponents(true)"
      >更换组件</el-button
    >
    <el-button
      v-if="props.showDeleteComponentsButton"
      type="danger"
      @click="handleDeleteComponents(true)"
      >删除组件</el-button
    >
    <el-button
      v-if="props.showInsertComponentsButton"
      @click="handleInsertComponents(true)"
      >插入组件</el-button
    >
    <el-button
      v-if="props.showSortComponentsButton && props.componentData.moveUp === 1"
      @click="handleSortComponents(0)"
      >上移</el-button
    >
    <el-button
      v-if="
        props.showSortComponentsButton && props.componentData.moveDown === 1
      "
      @click="handleSortComponents(1)"
      >下移</el-button
    >
  </div>
</template>
<script setup>
// #region 引用

// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default: () => ({}),
  },
});
/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
]);

// #endregion

// #region 变量/常量

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法
/**
 * 编辑按钮操作
 */
const handleShowEditForm = () => {
  emits('handle-show-edit-form', true);
};
/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 * flag 0 上移，1下移
 */
const handleSortComponents = (flag) => {
  emits('handle-sort-components', {
    flag,
    currentId: props.componentData.id,
  });
};

// #endregion
</script>
<style scoped>
.operation-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 10%);
}

.operation-container button {
  margin: 8px;
}
</style>
