<template>
  <div>
    <div
      class="image-text-container"
      :style="{
        backgroundImage: `url(${configDataForm.backgroundImageFullUrl})`,
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        'background-position': 'center',
      }"
      @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
      @mouseleave="handleShowOperationBtn(false)"
    >
      <div class="image-text">
        <span class="image-text-container-content">
          <div class="image-text-container-content-title">
            <div class="image-text-container-content-title-main-title">
              {{ configDataForm.title }}
            </div>
            <div
              v-if="configDataForm.subTitle"
              class="image-text-container-content-title-about-us"
            >
              {{ configDataForm.subTitle }}
            </div>
          </div>
          <div class="image-text-container-content-content">
            <div class="image-text-container-image">
              <img :src="configDataForm.imageFullUrl" />
            </div>
            {{ configDataForm.content }}
          </div>
          <div
            v-if="configDataForm.linkUrl"
            class="image-text-container-content-more"
          >
            <a
              target="_blank"
              :href="configDataForm.linkUrl"
              :style="{
                'background-color': configDataForm.backgroundColor,
                'border-color': configDataForm.textColor,
                color: configDataForm.textColor,
              }"
            >
              → 了解更多
            </a>
          </div>
        </span>
      </div>
      <client-only>
        <operation-container
          v-if="showOperationBtn && !showEditForm"
          :component-data="props.componentData"
          :show-edit-content-button="props.showEditContentButton"
          :show-edit-components-button="props.showEditComponentsButton"
          :show-delete-components-button="props.showDeleteComponentsButton"
          :show-insert-components-button="props.showInsertComponentsButton"
          :show-sort-components-button="props.showSortComponentsButton"
          @handle-show-edit-form="handleShowEditForm"
          @handle-change-components="handleChangeComponents"
          @handle-delete-components="handleDeleteComponents"
          @handle-insert-components="handleInsertComponents"
          @handle-sort-components="handleSortComponents"
        ></operation-container>
      </client-only>
    </div>
    <client-only>
      <el-drawer
        v-model="showEditForm"
        size="60%"
        :title="props.componentData.componentName"
      >
        <image-text-edit-form
          v-if="showEditForm"
          v-model="configDataForm"
          :component-data="props.componentData"
          @handle-show-edit-form="handleShowEditForm"
          @handle-edit-components-content="handleEditComponentsContent"
        ></image-text-edit-form>
      </el-drawer>
    </client-only>
  </div>
</template>
<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import OperationContainer from '../common/OperationContainer.vue';
import ImageTextEditForm from './ImageTextEditFormFour.vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

// console.log('props-configDataForm:',props);

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量

// 表单绑定对象
const configDataForm = ref({
  contentImageObj: null,
  imageName: '',
  imageFileKey: 'contentImage',
  content:
    '成都众创益优科技有限公司（简称“众创益优”）成立于2015年，核心成员是几位连续创业者。公司拥有二十余位由博士、硕士、学士等组成的技术团队。在多年的经营过程中，公司致力于提供数字化综合服务，始终坚持以客户为中心，以数字化技术帮助客户解决经营管理中的痛点问题。我们在电商、康养、高等教育等领域成功研发了多个大型的、业务复杂的、高并发的信息平台，强有力的支撑了客户对业务数字化的需求。多年以来，我们基于高水平的技术团队和丰富的开发维护经验，在电商、康养、高等教育等领域能从0到1为客户提供了管理咨询、需求调研、分析、设计、开发、实施、维护、跟踪升级等全套优质服务。',
  textColor: '#ffffff',
  backgroundColor: '#1e93ea',
});

if (props.componentData.configData) {
  // 服务器渲染的直接调用
  configDataForm.value = JSON.parse(props.componentData.configData);
  // 后台编辑的处理一下
  // nextTick(() => {
  //   configDataForm.value = JSON.parse(props.componentData.configData);
  // });
}

// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法
/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};
/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};
/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // console.log('configDataForm--1:', configDataForm);
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};

// #endregion
</script>
<style scoped>
@media screen and (device-width >= 1180px) {
  .image-text-container-content {
    width: 1200px;
  }

  /*
  *文字环绕图片处理
   */

  .image-text-container-content .image-text-container-image {
    float: left;
    width: 508px;
    height: 338px;
    padding: 20px;
    overflow: hidden;
    clear: both;
  }

  .image-text-container-content .image-text-container-image img {
    width: 100%;
    height: 100%;
  }

  .image-text-container-content-title {
    margin-bottom: 0;
    border-bottom: unset;
  }
}
</style>

<style scoped>
@media screen and (device-width <= 1180px) {
  .image-text-container-content-title {
    margin-bottom: 0;
    border-bottom: unset;
  }

  .image-text-container-content-content {
    display: flex;
    flex-wrap: wrap-reverse;
  }
}
</style>
