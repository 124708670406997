<template>
  <ul class="article-list">
    <li class="list-item" v-for="(item, index) in props.contentList">
      <a :href="item.url">
        <h4>{{ item.title }}</h4>
        <span>{{ item.publishTime }}</span>
      </a>
    </li>
  </ul>
</template>
<script setup>
//#region 引用

//#endregion

//#region props/emit
const props = defineProps({
  contentList: {
    type: Array,
    default: [],
  },
});
//#endregion

//#region 变量/常量

//#endregion

//#region 生命周期

//#endregion

//#region 业务方法

//#endregion
</script>
<style scoped>
.article-list {
  flex-grow: 1;
}

.article-list .list-item {
  border-bottom: 1px dotted #ccc;
  text-align: left;
  font-size: 16px;
  line-height: 42px;
  background: url(./image/spot.png) no-repeat left center;
}

.article-list .list-item a {
  overflow: hidden;
  display: block;
  width: 100%;
}

.article-list .list-item a h4 {
  float: left;
  color: #666;
  margin-left: 10px;
  line-height: 42px;
}

.article-list .list-item a h4:hover {
  text-decoration: underline;
}

.article-list .list-item a span {
  color: #666;
  cursor: auto;
  float: right;
}

.article-list .list-item::after {
  clear: both;
}
</style>
