export default [
  {
    path: '/preview/:id',
    name: 'website-home-preview',
    component: () => import('@/views/website/home/Index.vue'),
    meta: { layout: 'website' },
  },
  {
    path: '/',
    name: 'website-home',
    component: () => import('@/views/website/home/Index.vue'),
    meta: { layout: 'website' },
  },
  {
    path: '/content/list/:columnId',
    name: 'website-content-list',
    component: () => import('@/views/website/content/List.vue'),
    meta: { layout: 'website' },
  },
  {
    path: '/content/list/:columnId/:id',
    name: 'website-content-list-preview',
    component: () => import('@/views/website/content/List.vue'),
    meta: { layout: 'website' },
  },
  {
    path: '/content/single/:columnId',
    name: 'website-content-single',
    component: () => import('@/views/website/content/Single.vue'),
    meta: { layout: 'website' },
  },
  {
    path: '/content/single/:columnId/:id',
    name: 'website-content-single-preview',
    component: () => import('@/views/website/content/Single.vue'),
    meta: { layout: 'website' },
  },
  {
    path: '/content/customer/:columnId/:pageTypeId',
    name: 'website-content-customer',
    component: () => import('@/views/website/content/Customer.vue'),
    meta: { layout: 'website' },
  },
  {
    path: '/content/customer/:columnId/:pageTypeId/:id',
    name: 'website-content-customer-preview',
    component: () => import('@/views/website/content/Customer.vue'),
    meta: { layout: 'website' },
  },
  {
    path: '/content/detail/:columnId/:materialId',
    name: 'website-content-detail',
    component: () => import('@/views/website/content/Detail.vue'),
    meta: { layout: 'website' },
  },
  {
    path: '/content/detail/:columnId/:materialId/:id',
    name: 'website-content-detail-preview',
    component: () => import('@/views/website/content/Detail.vue'),
    meta: { layout: 'website' },
  },

  /* ------------------------ */

  {
    path: '/404',
    name: 'error-404',
    component: () => import('@/views/empty/error/Error404.vue'),
    meta: { layout: 'empty', noTab: true },
  },
  {
    path: '/500',
    name: 'error-500',
    component: () => import('@/views/empty/error/Error500.vue'),
    meta: { layout: 'empty', noTab: true },
  },
  {
    path: '/:catchAll(.*)',
    name: 'error-all',
    component: () => import('@/views/empty/error/Error404.vue'),
    meta: { layout: 'empty', noTab: true },
  },
];
