<template>
  <component
    :is="dom[bundleComponent(props.componentData.componentCode)]"
    :key="new Date()"
    v-model="configDataForm"
    :class="props.componentData.fromType === 3 ? 'image-text-front' : ''"
    :component-data="props.componentData"
    :show-edit-content-button="props.showEditContentButton"
    :show-edit-components-button="props.showEditComponentsButton"
    :show-delete-components-button="props.showDeleteComponentsButton"
    :show-insert-components-button="props.showInsertComponentsButton"
    :show-sort-components-button="props.showSortComponentsButton"
    @handle-edit-components-content="handleEditComponentsContent"
    @handle-change-components="handleChangeComponents"
    @handle-delete-components="handleDeleteComponents"
    @handle-insert-components="handleInsertComponents"
    @handle-sort-components="handleSortComponents"
  ></component>
</template>
<script setup>
// #region 引用
import imageTextThree from './ImageTextThree.vue'; // 图文组件3
import imageTextFour from './ImageTextFour.vue'; // 图文组件4
import imageTextFive from './ImageTextFive.vue'; // 图文组件5
import imageTextSix from './ImageTextSix.vue'; // 图文组件6
import imageTextSeven from './ImageTextSeven.vue'; // 图文组件7
import imageTextEight from './ImageTextEight.vue'; // 图文组件8
import imageTextNine from './ImageTextNine.vue'; // 图文组件9
import imageTextTen from './ImageTextTen.vue'; // 图文组件10
import imageTextEleven from './ImageTextEleven.vue'; // 图文组件11
import imageTextTwelve from './ImageTextTwelve.vue'; // 图文组件12
import imageTextThirteen from './ImageTextThirteen.vue'; // 图文组件13
import imageTextFourteenth from './ImageTextFourteenth.vue'; // 图文组件14
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);
// #endregion

// #region 变量/常量

/**
 * 动态渲染组件
 */
const dom = shallowReactive({
  imageTextThree,
  imageTextFour,
  imageTextFive,
  imageTextSix,
  imageTextSeven,
  imageTextEight,
  imageTextNine,
  imageTextTen,
  imageTextEleven,
  imageTextTwelve,
  imageTextThirteen,
  imageTextFourteenth,
});

const configDataForm = ref(null);
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 绑定组件
 * @param {*} componentType
 */
const bundleComponent = (componentCode) => {
  let component = '';
  switch (componentCode) {
    case 'IMAGE_TEXT_3':
      component = 'imageTextThree';
      break;
    case 'IMAGE_TEXT_4':
      component = 'imageTextFour';
      break;
    case 'IMAGE_TEXT_5':
      component = 'imageTextFive';
      break;
    case 'IMAGE_TEXT_6':
      component = 'imageTextSix';
      break;
    case 'IMAGE_TEXT_7':
      component = 'imageTextSeven';
      break;
    case 'IMAGE_TEXT_8':
      component = 'imageTextEight';
      break;
    case 'IMAGE_TEXT_9':
      component = 'imageTextNine';
      break;
    case 'IMAGE_TEXT_10':
      component = 'imageTextTen';
      break;
    case 'IMAGE_TEXT_11':
      component = 'imageTextEleven';
      break;
    case 'IMAGE_TEXT_12':
      component = 'imageTextTwelve';
      break;
    case 'IMAGE_TEXT_13':
      component = 'imageTextThirteen';
      break;
    case 'IMAGE_TEXT_14':
      component = 'imageTextFourteenth';
      break;
    default:
      break;
  }
  return component;
};

/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('handle-edit-components-content', false);
    emits('update:modelValue', configDataForm.value);
    // console.log('configDataForm--6:', configDataForm);
  });
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  // console.log('handle-insert-components3');

  emits('handle-insert-components', true);
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};
// #endregion
</script>

<script type="text/javascript">
if (!import.meta.env.SSR) {
  /** *
   * 定义一个防抖函数
   */
  const debounce = (fn, delay = 500) => {
    let timer = null;
    return function (...args) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  };

  // 获取所有图片
  const imgList = Array.from(
    document.getElementsByClassName('image-text-container-image'),
  );
  // 用于记录当前显示到了哪一张图片

  /**
   * 获取全部的文本内容，
   */
  const fadeInElements = Array.from(
    document.getElementsByClassName('image-text-container-content'),
  );
  /**
   * 判断是否显示
   */
  const isElemVisible = (el) => {
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top + 200; // 200 = buffer
    const elemBottom = rect.bottom;
    return elemTop < window.innerHeight && elemBottom >= 0;
  };
  /**
   * 处理滚动效果
   */
  const handleScroll = (evt) => {
    // debounce(lazyload, 600);//图片加载处理
    /**
     * 文字处理
     */
    for (let i = 0; i < fadeInElements.length; i += 1) {
      const elem = fadeInElements[i];
      if (isElemVisible(elem)) {
        elem.classList.add('fadeInLeft');
        fadeInElements.splice(i, 1); // 只让它运行一次
      }
    }

    /**
     * 图片处理
     */
    for (let i = 0; i < imgList.length; i += 1) {
      const elem = imgList[i];
      if (isElemVisible(elem)) {
        elem.classList.add('fadeInLeft');
        imgList.splice(i, 1); // 只让它运行一次
      }
    }
  };
  /**
   * PC端添加滚动条监听事件
   */
  // eslint-disable-next-line no-unused-expressions
  document && document.addEventListener('scroll', debounce(handleScroll, 10));
  /**
   * 移动端添加滚动监听事件
   */
  // eslint-disable-next-line no-unused-expressions
  document &&
    document.addEventListener('touchmove', debounce(handleScroll, 10));

  handleScroll();
}
</script>

<style>
/*
*表单样式
*/
.image-text-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  margin: auto;
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.image-text-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: var(--el-color-white);
}

.image-text-btn-container button {
  margin: 8px;
}

.image-text-front .image-text-container-image,
.image-text-front .image-text-container-content {
  opacity: 0;
}

/* 从左到右进入 */
/* stylelint-disable-next-line selector-class-pattern */
.fadeInLeft {
  opacity: 1 !important;
  animation-name: fadeInLeft;
  animation-duration: 1s;
}

/* 从左到右进入 */
/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

/* 从右到右进入 */
/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

/* 从右到右进入 */
/* stylelint-disable-next-line selector-class-pattern */
.fadeInRight {
  opacity: 1 !important;
  animation-name: fadeInRight;
  animation-duration: 1s;
}
</style>

<style>
@media screen and (device-width >= 1180px) {
  .image-text-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    margin: 50px 0;
  }

  .image-text-container img {
    width: 100%;
    height: 100%;
  }

  .image-text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    border-top: 0 none transparent;
    border-right: 0 none transparent;
    border-bottom: 0 none transparent;
    border-left: 0 none transparent;
    border-radius: 0;
  }

  .image-text-container-image {
    width: 508px;
    height: 338px;
    padding: 10px;
    overflow: hidden;
    border-radius: 10px;
  }

  .image-text-container-image img {
    height: 100%;
    border-radius: 10px;
    transition: transform 0.3s linear;
  }

  .image-text-container-image:hover img {
    transform: scale(1.1);
  }

  .image-text-container-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 615px;
    min-height: 348px;
    margin: 10px 40px;
    line-height: 2;
    white-space: pre-wrap;
  }

  .image-text-container-content-title {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid;
    border-bottom-color: var(--el-color-grey-light);
  }

  .image-text-container-content-title-about-us {
    margin-left: 10px;
    font-size: 16px;
    color: #00a0d0;
  }

  .image-text-container-content-title-about-us .us {
    color: #666;
  }

  .image-text-container-content-title-main-title {
    font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    color: #363636;
  }

  .image-text-container-content-content {
    font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
    font-size: 16px;
    line-height: 2;
    color: #666;
    letter-spacing: 1.2px;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .image-text-container-content-more a {
    display: inline-block;
    width: 138px;
    padding: 5px;
    text-align: center;
    border: 2px solid;
  }
}
</style>

<style>
@media screen and (device-width <= 1180px) {
  .image-text-container {
    width: 100%;
    padding: 0 2vw;

    /* margin: 50px 0; */
  }

  .image-text-container img {
    width: 100%;
    height: 100%;
  }

  .image-text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding: 0.1vw;
    border-top: 0 none transparent;
    border-right: 0 none transparent;
    border-bottom: 0 none transparent;
    border-left: 0 none transparent;
    border-radius: 0;
  }

  .image-text-container-image {
    width: 100%;
    height: 100%;
    padding: 2.5vh 0.1vw;
    overflow: hidden;
    border-radius: 2.5vw;
  }

  .image-text-container-image img {
    height: 100%;
    border-radius: 2.5vw;
    transition: transform 0.3s linear;
  }

  .image-text-container-image:hover img {
    transform: scale(1.1);
  }

  .image-text-container-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    padding: 0.5vw;
    line-height: 2;
    white-space: pre-wrap;
  }

  .image-text-container-content-title {
    display: flex;
    align-items: center;
    padding-bottom: 5vw;
    margin-bottom: 5vw;
    font-size: 5vw;
    border-bottom: 0.25vw solid;
    border-bottom-color: var(--el-color-grey-light);
  }

  .image-text-container-content-title-about-us {
    margin-left: 0.625vw;
    font-size: 4vw;
    color: #00a0d0;
  }

  .image-text-container-content-title-about-us .us {
    color: #666;
  }

  .image-text-container-content-title-main-title {
    font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
    font-size: 5vw;
    font-style: normal;
    font-weight: 700;
    color: #363636;
  }

  .image-text-container-content-content {
    font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
    font-size: 4vw;
    line-height: 2;
    color: #666;
    letter-spacing: 0.3vw;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .image-text-container-content-more a {
    justify-self: self-end;
    float: right;
    width: 28vw;
    font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
    font-size: 4vw;
    text-align: center;

    /* padding: 2.5vw 5vw; */
    border: 0.5vw solid;
  }
}
</style>
