<template>
  <component
    :is="dom[bundleComponent(props.componentData.componentCode)]"
    :key="new Date()"
    v-model="configDataForm"
    :component-data="props.componentData"
    :show-edit-content-button="props.showEditContentButton"
    :show-edit-components-button="props.showEditComponentsButton"
    :show-delete-components-button="props.showDeleteComponentsButton"
    :show-insert-components-button="props.showInsertComponentsButton"
    :show-sort-components-button="props.showSortComponentsButton"
    @handle-edit-components-content="handleEditComponentsContent"
    @handle-change-components="handleChangeComponents"
    @handle-delete-components="handleDeleteComponents"
    @handle-insert-components="handleInsertComponents"
    @handle-sort-components="handleSortComponents"
  ></component>
</template>
<script setup>
// #region 引用
import FriendLinkThree from './FriendLinkThree.vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});
/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);
// #endregion

// #region 变量/常量
const configDataForm = ref([
  {
    sortNum: 1,
    siteName: '天府老年大学',
    siteUrl: 'http://www.tfu3a.com/',
  },
  {
    sortNum: 2,
    siteName: '多米岛',
    siteUrl: 'http://www.dormistore.com/',
  },
  {
    sortNum: 3,
    siteName: '康养护理学院',
    siteUrl: 'https://kyhl.tfswufe.edu.cn/',
  },
  {
    sortNum: 4,
    siteName: '乐力',
    siteUrl: 'https://www.leliwell.com/',
  },
]);
/**
 * 动态渲染组件
 */
const dom = shallowReactive({
  FriendLinkThree,
});
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 绑定组件
 * @param {*} componentType
 */
const bundleComponent = (componentCode) => {
  let component = '';
  switch (componentCode) {
    case 'FRIEND_LINK_3':
      component = 'FriendLinkThree';
      break;
    default:
      break;
  }
  return component;
};
/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('handle-edit-components-content', false);
    emits('update:modelValue', configDataForm.value);
    // console.log('configDataForm--6:', configDataForm);
  });
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  // console.log('handle-insert-components3');

  emits('handle-insert-components', true);
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};
// #endregion
</script>
