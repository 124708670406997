<template>
  <div>
    <div
      class="image-text-set-container"
      @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
      @mouseleave="handleShowOperationBtn(false)"
    >
      <div v-if="imageArticleListFirstLine.length > 0" class="first-line">
        <span
          v-for="item in imageArticleListFirstLine"
          :key="item.id"
          class="image-text-set-item"
          :style="{
            'background-color': item.backgroundColor,
            color: configDataForm.fontColor,
          }"
        >
          <span class="item-icon">
            <el-image :src="item.iconFullUrl"></el-image>
          </span>
          <span class="item-title">{{ item.title }}</span>
          <span class="item-link">查看详细</span>
        </span>

        <span
          v-if="imageArticleListFirstLine.length < 5"
          class="image-text-set-item"
        >
          <span class="item-dot">
            <span></span>
            <span></span>
            <span></span>
          </span>
          <span class="item-link">更多</span>
        </span>
      </div>
      <div v-if="imageArticleListSecondLine.length > 0" class="second-line">
        <span
          v-for="item in imageArticleListSecondLine"
          :key="item.id"
          class="image-text-set-item"
          :style="{
            'background-color': item.backgroundColor,
            color: configDataForm.fontColor,
          }"
        >
          <span class="item-icon">
            <el-image :src="item.iconFullUrl"></el-image>
          </span>
          <span class="item-title">{{ item.title }}</span>
          <span class="item-link">查看详细</span>
        </span>
        <span
          v-if="imageArticleListSecondLine.length < 5"
          class="image-text-set-item"
        >
          <span class="item-dot">
            <span></span>
            <span></span>
            <span></span>
          </span>
          <span class="item-link">更多</span>
        </span>
      </div>

      <client-only>
        <operation-container
          v-if="showOperationBtn && !showEditForm"
          :component-data="props.componentData"
          :show-edit-content-button="props.showEditContentButton"
          :show-edit-components-button="props.showEditComponentsButton"
          :show-delete-components-button="props.showDeleteComponentsButton"
          :show-insert-components-button="props.showInsertComponentsButton"
          :show-sort-components-button="props.showSortComponentsButton"
          @handle-show-edit-form="handleShowEditForm"
          @handle-change-components="handleChangeComponents"
          @handle-delete-components="handleDeleteComponents"
          @handle-insert-components="handleInsertComponents"
          @handle-sort-components="handleSortComponents"
        ></operation-container>
      </client-only>
    </div>
    <client-only>
      <el-drawer
        v-model="showEditForm"
        size="60%"
        :title="props.componentData.componentName"
      >
        <image-text-set-edit-form
          v-if="showEditForm"
          v-model="configDataForm"
          :component-data="props.componentData"
          @handle-show-edit-form="handleShowEditForm"
          @handle-edit-components-content="handleEditComponentsContent"
        ></image-text-set-edit-form>
      </el-drawer>
    </client-only>
  </div>
</template>
<script setup>
// #region 引用
import { ClientOnly } from 'vite-ssr';
import OperationContainer from '../common/OperationContainer.vue';
import ImageTextSetEditForm from './ImageTextSetEditForm.vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});
// console.log('props-ImageTextSetTwo:',props);

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量

const configDataForm = ref({
  items: [
    {
      sortNum: 1,
      iconName: '示例图片2.jpg',
      iconFileKey: 'icon1',
      title: '任务管理及绩效管理',
      backgroundColor: '#fbbe2d',
      fontColor: '#ffffff',
      linkType: 2,
      linkUrl: 'https://www.baidu.com',
    },
    {
      sortNum: 2,
      iconName: '示例图片2.jpg',
      iconFileKey: 'icon2',
      title: '养老院管理系统',
      backgroundColor: '#1e93ea',
      fontColor: '#ffffff',
      linkType: 1,
      linkUrl: '/article-list/132',
    },
    {
      sortNum: 2,
      iconName: '示例图片2.jpg',
      iconFileKey: 'icon2',
      title: '移动电子商务',
      backgroundColor: '#a478db',
      fontColor: '#ffffff',
      linkType: 1,
      linkUrl: '/article-list/132',
    },
    {
      sortNum: 2,
      iconName: '示例图片2.jpg',
      iconFileKey: 'icon2',
      title: '幼儿园管理系统',
      backgroundColor: '#ef5e61',
      fontColor: '#ffffff',
      linkType: 1,
      linkUrl: '/article-list/132',
    },
    {
      sortNum: 2,
      iconName: '示例图片2.jpg',
      iconFileKey: 'icon2',
      title: '职业培训管理系统',
      backgroundColor: '#61b400',
      fontColor: '#ffffff',
      linkType: 1,
      linkUrl: '/article-list/132',
    },
  ],
});
if (props.componentData.configData) {
  configDataForm.value = JSON.parse(props.componentData.configData);
}
/**
 * 第一行显示
 */
const imageArticleListFirstLine = configDataForm.value.items.slice(0, 5);
const imageArticleListSecondLine = configDataForm.value.items.slice(5, 9);

// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法
/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};
/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};

/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // console.log('configDataForm--1:', configDataForm);
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};

// #endregion
</script>
<style scoped>
.image-text-set-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1200px;
  margin: 0 auto;
}

.first-line,
.second-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  /* align-items: first baseline; */
}

.image-text-set-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 220px;
  height: 200px;
  margin-right: 24px;
  margin-bottom: 50px;
  background-color: #ef5e61;
  border-radius: 10px;
  transition: box-shadow 0.5s;
  transition: all 0.2s linear;
}

/**
鼠标放上去效果 */
.image-text-set-item:hover {
  box-shadow: 5px 5px 10px gray;
  transform: translate3d(0, -10px, 0);
}

.image-text-set-item span {
  display: inline-block;
  font-size: 16px;
  color: #fff;
}

.image-text-set-item span.item-link {
  width: 110px;
  height: 30px;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  border: 0.5px solid #fff;
  border-radius: 15px;
}

/**
三个点点
*/
.image-text-set-item span.item-dot {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100px;

  /* align-items: last baseline; */
}

.image-text-set-item span.item-dot > span {
  position: relative;
  top: 30px;
  width: 13px;
  height: 13px;
  margin: auto 8px;
  background-color: #fff;
  border-radius: 50%;
}

:deep(.el-color-picker__trigger) {
  width: 100px;
}
</style>
