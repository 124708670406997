<template>
  <location
    v-if="contentNavList != null && contentNavList.length > 0"
    :content-nav-list="contentNavList"
  ></location>
  <div class="article-list-container">
    <div v-if="list != null && list.length > 0" class="article-list-item">
      <component :is="dom[bundleComponent(styeCode)]" :content-list="list">
      </component>
    </div>
    <zc-web-pager-ssr
      v-if="total != null && total > 0"
      :page-size="pageSize"
      :total-row-count="total"
    ></zc-web-pager-ssr>
  </div>
</template>

<script setup async>
// #region 引用
import { ColumnType, OwPageType } from '@/configs/enums/website';
import { getColumnPageUrl, getMaterialPageUrl } from '@/utils/business/website';
import {
  getMaterialArticlesList,
  getMaterialAttachmentsList,
  getMaterialImagesList,
  getMaterialVideosList,
} from '@/apis/modules/auth/website/website-ssr';
import useSSRInitialStateStore from '@/stores/ssr-state';
import { useSSRContext } from 'vue';
import articleListOne from '../components/article-list/ArticleListOne.vue';
import articleListTwo from '../components/article-list/ArticleListTwo.vue';
import location from '../components/Location.vue';

// #endregion

// #region props/emit

// #endregion

// #region 变量/常量

const configInfo = ref({}); // 配置信息
const configPageList = ref([]); // 配置页面列表

const route = useRoute();
/**
 * 数据列表
 */
const list = ref([]);
/**
 * 总的条数
 */
const total = ref(0);
/**
 * 当前操作页码
 */
const pageIndex = ref(1);
if (route.query && route.query.page) {
  pageIndex.value = route.query.page;
}
/**
 * 每页显示条数（默认风格1，默认显示3数据）
 */
const pageSize = ref(3);

// 栏目信息
const columnInfo = ref();
const previewId = ref();

// 导航地址信息
const contentNavList = ref([]);

const styeCode = ref('ARTICLE_LIST_1');

/**
 * 动态渲染组件
 */
const dom = shallowReactive({
  articleListTwo,
  articleListOne,
});

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 绑定组件
 * @param {*} stye 页面样式
 */
const bundleComponent = (stye) => {
  let component = '';
  switch (stye) {
    case 'ARTICLE_LIST_1':
      component = 'articleListOne';
      break;
    case 'ARTICLE_LIST_2':
      component = 'articleListTwo';
      break;
    case 'ARTICLE_LIST_3':
      // TODO
      component = 'articleListTwo';
      break;
    case 'ARTICLE_LIST_4':
      // TODO
      component = 'articleListTwo';
      break;
    case 'IMAGE_LIST_1':
      // TODO
      component = 'articleListTwo';
      break;
    case 'IMAGE_LIST_2':
      // TODO
      component = 'articleListTwo';
      break;
    case 'ATTACHMENT_LIST_1':
      // TODO
      component = 'articleListTwo';
      break;
    case 'ATTACHMENT_LIST_2':
      // TODO
      component = 'articleListTwo';
      break;
    case 'VIDEO_LIST_1':
      // TODO
      component = 'articleListTwo';
      break;
    case 'AUDIO_LIST_1':
      // TODO
      component = 'articleListTwo';
      break;
    default:
      component = 'articleListOne';
      break;
  }
  return component;
};

onServerPrefetch(async () => {
  const ssrContext = useSSRContext();
  configInfo.value = ssrContext.initialState.data.current.configInfo;
  configPageList.value = ssrContext.initialState.data.current.configPageList;
  columnInfo.value = ssrContext.initialState.data.current.columnInfo;
  previewId.value = ssrContext.initialState.data.current.previewId;
  contentNavList.value.push({
    title: columnInfo.value.columnName,
    url: getColumnPageUrl(
      columnInfo.value.columnType,
      columnInfo.value.id,
      previewId.value,
    ),
  });
  if (columnInfo.value) {
    useHead({
      title: `${columnInfo.value.columnName} - ${configInfo.value.homeTitle}`,
      meta: [
        // 设置栏目名称
        {
          name: 'keywords',
          content: columnInfo.value.columnName,
        },
        // 设置栏目摘要
        {
          name: 'description',
          content: columnInfo.value.digest,
        },
      ],
    });
  }

  /**
   * 处理渲染哪一个页面
   */
  let apiUrl = '';

  switch (columnInfo.value.columnType) {
    case ColumnType.文章列表栏目:
      styeCode.value = configPageList.value.find(
        (f) => f.pageType === OwPageType.文章列表页,
      ).owPageStyleCode;
      apiUrl = getMaterialArticlesList;
      break;
    case ColumnType.图片列表栏目:
      styeCode.value = configPageList.value.find(
        (f) => f.pageType === OwPageType.图片列表页,
      ).owPageStyleCode;
      apiUrl = getMaterialImagesList;
      break;
    case ColumnType.附件列表栏目:
      styeCode.value = configPageList.value.find(
        (f) => f.pageType === OwPageType.附件列表页,
      ).owPageStyleCode;
      apiUrl = getMaterialAttachmentsList;
      break;
    case ColumnType.视频列表栏目:
      styeCode.value = configPageList.value.find(
        (f) => f.pageType === OwPageType.视频列表页,
      ).owPageStyleCode;
      apiUrl = getMaterialVideosList;
      break;
    default:
      break;
  }

  /**
   * 获取栏目内容列表
   */
  const res = await apiUrl.use({
    columnId: route.params.columnId,
    pageIndex: pageIndex.value,
    pageSize: pageSize.value,
  });

  if (res.code === '00000' && res.data) {
    total.value = res.data.total;
    res.data.list.forEach((element) => {
      const newElement = {
        ...element,
        url: getMaterialPageUrl(
          route.params.columnId,
          element.materialId,
          previewId.value,
        ),
        coverImageUrl: element.coverImageUrl.replaceAll(
          '/upload/',
          `${import.meta.env.VITE_API_URL}/upload/`,
        ),
      };
      list.value.push(newElement);
    });
  }

  ssrContext.initialState.data.current.listData = list.value;
  ssrContext.initialState.data.current.total = total.value;
  ssrContext.initialState.data.current.contentNavList = contentNavList.value;

  // #endregion
});

if (!import.meta.env.SSR) {
  const ssrInitialState = useSSRInitialStateStore();
  list.value = ssrInitialState.data.current.listData;
  contentNavList.value = ssrInitialState.data.current.contentNavList;
  console.log('🚀 ~ contentNavList.value:', contentNavList.value);
  total.value = ssrInitialState.data.current.total;
}
</script>
<style scoped>
@media screen and (device-width >= 1180px) {
  .article-list-container {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: 0 auto;

    /* height: 100%; */
  }

  .article-list-item {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .article-list-pagination {
    align-self: flex-end;
    padding: 10px 0;
  }
}
</style>
<style scoped>
@media screen and (device-width <= 1180px) {
  .article-list-container {
    display: flex;
    flex-direction: column;
    width: 1005;
    margin: 0 auto;

    /* height: 100%; */
  }

  .article-list-item {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .article-list-pagination {
    align-self: flex-end;
    padding: 2.5vw 0;
  }
}
</style>
