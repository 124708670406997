<template>
  <div class="friend-link-form-container">
    <el-form
      ref="ruleFormRef"
      :size="formSize"
      :model="model"
      label-position="right"
      :rules="rules"
      label-width="220px"
      label-suffix="："
    >
      <el-table
        ref="tableRef"
        :data="model.configDataForm"
        :scrollbar-always-on="true"
        class="table-data fixed-head"
        :border="true"
      >
        <el-table-column type="index" width="60" label="序号" align="center" />
        <el-table-column label="网站名称" align="center">
          <template #default="scope">
            <el-form-item
              :prop="`configDataForm[${scope.$index}].siteName`"
              :rules="rules.siteName"
            >
              <el-input v-model="model.configDataForm[scope.$index].siteName">
              </el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="网址" align="center">
          <template #default="scope">
            <el-form-item
              :prop="`configDataForm[${scope.$index}].siteUrl`"
              :rules="rules.siteUrl"
            >
              <el-input v-model="model.configDataForm[scope.$index].siteUrl">
              </el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="60">
          <template #default="scope">
            <zc-table-operate-wrap :key="Math.random()" :max-item-count="3">
              <template v-if="model.configDataForm.length > 1">
                <el-link type="danger" @click="handleDel(scope)">
                  移除
                </el-link>
              </template>
              <template v-else>
                <el-tooltip content="至少保留一条友情链接" placement="top">
                  <el-link type="danger" disabled> 移除 </el-link>
                </el-tooltip>
              </template>
            </zc-table-operate-wrap>
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col :span="24">
          <el-button
            style="width: 100%; margin-top: 10px"
            @click="handleAdd(scope)"
          >
            <el-icon><Plus /></el-icon>添加三行
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <div class="friend-link-btn-container">
      <el-button @click="handleCancel()">取消</el-button>
      <el-button type="primary" @click="handleSave(ruleFormRef)"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script setup>
// #region 引用
import { verifyCharacterLength } from '@/utils/common/form-verify-util';
import { Plus } from '@element-plus/icons-vue';

// #endregion

// #region props/emit

/**
 * 属性
 */
const props = defineProps({
  componentData: {
    type: Object,
    default() {},
  },
});
/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'update:modelValue',
  'handle-edit-components-content',
]);

// #endregion

// #region 变量/常量

// 栏目数据列表

// 表单size
const formSize = ref('default');
const ruleFormRef = ref();

// 表单绑定对象

const model = ref({
  configDataForm: [
    {
      sortNum: 1,
      siteName: '天府老年大学',
      siteUrl: 'http://www.tfu3a.com/',
    },
    {
      sortNum: 2,
      siteName: '多米岛',
      siteUrl: 'http://www.dormistore.com/',
    },
    {
      sortNum: 3,
      siteName: '康养护理学院',
      siteUrl: 'https://kyhl.tfswufe.edu.cn/',
    },
    {
      sortNum: 4,
      siteName: '乐力',
      siteUrl: 'https://www.leliwell.com/',
    },
  ],
});

// 表单绑定对象
// console.log('props-configDataForm-FriendLinkEditForm:', props);

if (props.componentData.configData) {
  model.value.configDataForm = JSON.parse(props.componentData.configData);
}
// 表单规则
const rules = ref({
  siteName: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 50, trigger: 'blur' },
  ],
  siteUrl: [
    { required: true, message: '必填项', trigger: 'blur' },
    { validator: verifyCharacterLength, clen: 200, trigger: 'blur' },
  ],
});
// #endregion
// console.log('configDataForm.value:', model.value.configDataForm);

// #region 生命周期

// #endregion

// #region 业务方法
/**
 * 处理取消
 */
const handleCancel = () => {
  emits('handle-show-edit-form', false);
};
/**
 * 处理保存
 */
const handleSave = (form) => {
  // if (!form) return;
  const formEl = ruleFormRef.value;

  formEl.validate((valid, fields) => {
    if (valid) {
      // console.log('configDataForm-ImageTextEditForm:', model.value.configDataForm);

      // 待数据更新完成后才执行
      nextTick(() => {
        emits('handle-edit-components-content', false);
        emits('update:modelValue', model.value.configDataForm);
      });
      // TODO 待完成保存逻辑
    } else {
      console.log('error submit!', fields);
      // formEl.scrollToField(Object.keys(fields)[0]);
    }
  });
};

/**
 * 处理删除
 */
const handleDel = (scope) => {
  // console.log(scope.$index);

  model.value.configDataForm.splice(scope.$index, 1);
  //   handleResetRules();
};

/**
 * 处理添加一条离职规则
 */
const handleAdd = () => {
  // 获取当前最大排序号

  const sortNumArr = model.value.configDataForm.map((f) => {
    return f.sortNum;
  });

  let sortNum = Math.max(...sortNumArr);

  model.value.configDataForm.push({
    sortNum: (sortNum += 1),
    siteName: '',
    siteUrl: '',
  });
  model.value.configDataForm.push({
    sortNum: (sortNum += 1),
    siteName: '',
    siteUrl: '',
  });
  model.value.configDataForm.push({
    sortNum: (sortNum += 1),
    siteName: '',
    siteUrl: '',
  });
  //   handleResetRules();
};

// #endregion
</script>

<style scoped>
.friend-link-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  margin: auto;
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.friend-link-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: var(--el-color-white);
}

.friend-link-btn-container button {
  margin: 8px;
}

:deep(.el-alert .el-icon) {
  font-size: 22px;
}

:deep(.el-alert__description) {
  margin: 0;
}

:deep(.el-table .cell) {
  padding: 0 12px !important;
}

:deep(.cell) {
  /* line-height: 50px; */
  display: flex;
  align-items: center;
  height: 66px;
}

:deep(.el-table .el-form-item) {
  width: 100%;
}

.table-append {
  min-height: 40px;
  padding: 0 12px;
  font-size: 13px;
  line-height: 35px;
  text-align: center;
  border-bottom: 1px solid var(--el-color-grey-light-2);
}

.table-append .el-link {
  font-size: 13px;
}

:deep(.el-table .el-form-item__content) {
  margin-left: unset !important;
}

/* stylelint-disable-next-line no-duplicate-selectors */
:deep(.el-table .el-form-item) {
  margin: unset !important;
}

/* stylelint-disable-next-line no-duplicate-selectors */
:deep(.el-table .el-form-item) {
  margin-top: 18px;
}

.form-item-label :deep(.el-form-item__label) {
  align-items: center;
}

:deep(.el-form) {
  width: 100%;
  padding: 20px;
}
</style>
