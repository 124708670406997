import viteSSR, { ClientOnly } from 'vite-ssr';
import { createHead } from '@vueuse/head';
import { ID_INJECTION_KEY, ZINDEX_INJECTION_KEY } from 'element-plus';
import useSSRInitialStateStore from '@/stores/ssr-state';
import routerSSR from './routers/index-ssr';

import AppSSR from './AppSSR.vue';

const options = {
  routes: routerSSR,
};

const pinia = createPinia();

export default viteSSR(AppSSR, options, (context) => {
  const { app, initialState, router } = context;
  const head = createHead();
  app.use(head);
  app.use(pinia);
  app.component(ClientOnly.name, ClientOnly);
  app.provide(ID_INJECTION_KEY, {
    prefix: Math.floor(Math.random() * 10000),
    current: 0,
  });
  app.provide(ZINDEX_INJECTION_KEY, {
    prefix: Math.floor(Math.random() * 10000),
    current: 0,
  });

  if (import.meta.env.SSR) {
    // 服务器端渲染时初始化initialState.data（服务端会在页面上构建window.__INITIAL_STATE__对象值）
    initialState.data = {};
  } else {
    // 客户端渲染时将initialState存放到pinia
    const ssrInitialState = useSSRInitialStateStore();
    ssrInitialState.setData(initialState.data);
  }

  return { head, app, router, initialState };
});
