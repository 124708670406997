<template>
  <component :is="dom[bundleComponent(styeCode)]"> </component>
</template>
<script setup>
// #region 引用
import { ColumnType, OwPageType } from '@/configs/enums/website';
import useSSRInitialStateStore from '@/stores/ssr-state';
import { useSSRContext } from 'vue';
import singleOne from './SingleOne.vue';
// #endregion

// #region props/emit

// #endregion

// #region 变量/常量
const styeCode = ref('ARTICLE_LIST_1');
const configInfo = ref({}); // 配置信息
const configPageList = ref(); // 配置页面列表
const columnInfo = ref();

/**
 * 动态渲染组件
 */
const dom = shallowReactive({
  singleOne,
});

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

/**
 * 获取栏目内容缓存内容
 */

onServerPrefetch(async () => {
  const ssrContext = useSSRContext();
  configInfo.value = ssrContext.initialState.data.current.configInfo;
  configPageList.value = ssrContext.initialState.data.current.configPageList;
  columnInfo.value = ssrContext.initialState.data.current.columnInfo;
  if (columnInfo.value) {
    useHead({
      title: `${columnInfo.value.columnName} - ${configInfo.value.homeTitle}`,
      meta: [
        // 设置栏目名称
        {
          name: 'keywords',
          content: columnInfo.value.columnName,
        },
        // 设置栏目摘要
        {
          name: 'description',
          content: columnInfo.value.digest,
        },
      ],
    });
  }
});

if (!import.meta.env.SSR) {
  const ssrInitialState = useSSRInitialStateStore();
  columnInfo.value = ssrInitialState.data.current.columnInfo;
  configPageList.value = ssrInitialState.data.current.configPageList;

  switch (columnInfo.value.columnType) {
    case ColumnType.单页面栏目:
      styeCode.value = configPageList.value.find(
        (f) => f.pageType === OwPageType.单页面,
      ).owPageStyleCode;
      break;
    default:
      styeCode.value = configPageList.value.find(
        (f) => f.pageType === OwPageType.单页面,
      ).owPageStyleCode;
      break;
  }
}

/**
 * 绑定组件
 * @param {*} stye 页面模板样式
 */
const bundleComponent = (stye) => {
  let component = '';
  switch (stye) {
    case 'SINGLE_1':
      component = 'singleOne';
      break;
    default:
      component = 'singleOne';
      break;
  }
  return component;
};

// #endregion
</script>
<style scoped></style>
