<!--
 * @description: 门户端分页组件
 * @Author: HZH
 * @Date: 2023-08-12
 * @remark：--
 -->
<template>
    <ul class="table-bottom">
      <li class="table-pager">
        <a :disabled="!showFirst" :href="linkFist">首页</a>
        <a :disabled="!showPrev" :href="linkPrev">上一页</a>
        <a v-if="showMorePrev" class="more more-prev" :href="linkMorePrev">...</a>
        <a v-for="(item, index) in pageLinkItems" :class="item == currentPageIndex ? 'current' : ''" :href="getLinkHref(item)">{{ item }}</a>
        <a v-if="showMoreNext" class="more more-next" :href="linkMoreNext">...</a>
        <a :disabled="!showNext" :href="linkNext">下一页</a>
        <a :disabled="!showLast" :href="linkLast">尾页</a>
      </li>
      <li class="right-content"></li>
    </ul>
</template>

<script setup>
//#region 引用

//#endregion

//#region props/emit

const props = defineProps({
  //数据总条数（必传）
  totalRowCount: Number,
  //当前页（非必传）
  currentPageIndex: {
    type: Number,
    default: 1,
  },
  //分页大小（非必传）
  pageSize: {
    type: Number,
    default: 20,
  },
});

const emit = defineEmits(['onChanged']);

//#endregion

//#region 变量/常量

const route = useRoute();
let basePath = route.path;
if (basePath.endsWith('/')) {
  basePath = basePath.substring(0, basePath.length - 1);
}
let pageQueryKey = 'page';
let maxPageCount = 9; //显示的最大分页数（建议设置奇数值）
let overRangeSize = maxPageCount - 2; //超出分页条数后，每段显示的分页数（去掉首页和尾页）
let halfRangeSize = parseInt(overRangeSize / 2) + parseInt(overRangeSize % 2);

//分页大小
const pageSize = ref(props.pageSize);

//当前页
const currentPageIndex = ref(props.currentPageIndex);
if (route.query && route.query.page) {
  currentPageIndex.value = route.query.page;
}

//总页数（根据总行数与分页大小自动计算）
const totalPageCount = computed(() => {
  let result = Math.ceil(props.totalRowCount / pageSize.value);
  result = result === 0 || !result ? 1 : result;
  return result;
});

//首页
const showFirst = ref(currentPageIndex.value > 1);
const linkFist = computed(() => {
  return showFirst.value ? basePath : 'javascript:void(0)';
});
//尾页
const showLast = ref(currentPageIndex.value < totalPageCount.value);
const linkLast = computed(() => {
  return showLast.value ? `${basePath}?${pageQueryKey}=${totalPageCount.value}` : 'javascript:void(0)';
});
//上一页
const showPrev = ref(currentPageIndex.value > 1);
const linkPrev = computed(() => {
  return showPrev.value
    ? currentPageIndex.value - 1 == 1
      ? basePath
      : `${basePath}?${pageQueryKey}=${parseInt(currentPageIndex.value) - 1}`
    : 'javascript:void(0)';
});
//下一页
const showNext = ref(currentPageIndex.value < totalPageCount.value);
const linkNext = computed(() => {
  return showNext.value ? `${basePath}?${pageQueryKey}=${parseInt(currentPageIndex.value) + 1}` : 'javascript:void(0)';
});

//首页码和尾页码（会根据分段信息不同而不同）
let pageLinkItemFirst = 1;
let pageLinkItemLast = totalPageCount.value;

//上一分页段
let showMorePrev = totalPageCount.value > maxPageCount && currentPageIndex.value > halfRangeSize;
let linkMorePrev = 'javascript:void(0)';
if (showMorePrev) {
  let linkMorePrevIndex = parseInt(currentPageIndex.value) - parseInt(halfRangeSize);
  pageLinkItemFirst = linkMorePrevIndex + 1;
  linkMorePrev = linkMorePrevIndex == 1 ? basePath : `${basePath}?${pageQueryKey}=${linkMorePrevIndex}`;
}

//下一分页段
let showMoreNext = totalPageCount.value > maxPageCount && currentPageIndex.value < totalPageCount.value - halfRangeSize + 1;
let linkMoreNext = 'javascript:void(0)';
if (showMoreNext) {
  let linkMoreNextIndex = parseInt(currentPageIndex.value) + parseInt(halfRangeSize);
  pageLinkItemLast = linkMoreNextIndex - 1;
  linkMoreNext = `${basePath}?${pageQueryKey}=${linkMoreNextIndex}`;
}

if (!showMorePrev && showMoreNext) {
  //如果不显示上一分页段，要显示下一分页段，则items尾值为 pageLinkItemFirst + overRangeSize
  pageLinkItemLast = pageLinkItemFirst + overRangeSize;
  linkMoreNext = `${basePath}?${pageQueryKey}=${pageLinkItemLast + 1}`;
} else if (showMorePrev && !showMoreNext) {
  //如果不显示下一分页段，要显示上一分页段，则items首值为 pageLinkItemLast - overRangeSize
  pageLinkItemFirst = pageLinkItemLast - overRangeSize;
  linkMorePrev = `${basePath}?${pageQueryKey}=${pageLinkItemFirst - 1}`;
}

//页面arr
let pageLinkItems = [];
for (let i = pageLinkItemFirst; i <= pageLinkItemLast; i++) {
  pageLinkItems.push(i);
}

//#endregion

//#region 业务方法

const getLinkHref = item => {
  return item == currentPageIndex.value ? 'javascript:void(0)' : item == 1 ? basePath : `${basePath}?${pageQueryKey}=${item}`;
};

//#endregion
</script>

<style scoped>
@media screen and (device-width >= 1180px) {
/* 数字输入框样式沉淀 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none !important;
}
input[type='number'] {
  appearance: textfield;
}

/* 分页样式 */
.table-bottom::after {
  display: block;
  clear: both;
  content: '';
}
.table-bottom .table-pager {
  float: right;
  padding: 8px 0;
}
.table-bottom a {
  float: left;
  height: 30px;
  padding: 0 12px;
  margin-left: 10px;
  line-height: 30px;
  cursor: pointer;
  background: #f0f2f5;
  border-radius: 4px;
}
.table-pager :first-child {
  margin-left: 0;
}
.table-pager a[disabled] {
  color: #999 !important;
  cursor: no-drop;
}
.table-pager > a.current {
  color: var(--el-color-white) !important;
  cursor: default;
  background: #008bd5;
}
.table-pager > a.more {
  font-size: 14px;
  font-weight: 700;
}

/* 右侧内容样式 */
.table-bottom .right-content {
  position: relative;
  float: left;
  padding: 0 8px 0 32px;
  margin: 8px 0;
  line-height: 32px;

  /* background: #e8f4ff; */
  background: var(--el-color-grey-light-16);
  border-radius: 4px;
}
.table-bottom .right-content.text-tips {
  display: block !important;
  padding-left: 0;
  margin: 0;
  line-height: 32px;
  background: var(--el-color-white);
}
.table-bottom .right-content .bottom-tips {
  position: absolute;
  top: 0;
  left: 8px;
  width: 30px;
  height: 30px;
  background: var(--el-base-icons) -25px -113px;
}

a{
  color: #008bd5 !important;
}}
</style>

<style scoped>
@media screen and (device-width <= 1180px){
/* 数字输入框样式沉淀 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none !important;
}
input[type='number'] {
  appearance: textfield;
}

/* 分页样式 */
.table-bottom::after {
  display: block;
  clear: both;
  content: '';
}
.table-bottom .table-pager {
  justify-self: center;
  padding: 2vw;
  margin: auto;
  margin: 1vw;
}
.table-bottom a {
  float: left;
  height: 8vw;
  padding: 0 3vw;
  margin-bottom: 2vw;
  margin-left: 2.5vw;
  line-height: 2;
  cursor: pointer;
  background: #f0f2f5;
  border-radius: 1vw;
}
.table-pager :first-child {
  margin-left: 0;
}
.table-pager a[disabled] {
  color: #999 !important;
  cursor: no-drop;
}
.table-pager > a.current {
  color: var(--el-color-white) !important;
  cursor: default;
  background: #008bd5;
}
.table-pager > a.more {
  font-size: 4vw;
  font-weight: 700;
}

/* 右侧内容样式 */
.table-bottom .right-content {
  position: relative;
  float: left;
  padding: 0 2vw 0 8vw;
  margin: 2vw 0;
  line-height: 8vw;

  /* background: #e8f4ff; */
  background: var(--el-color-grey-light-16);
  border-radius: 1vw;
}
.table-bottom .right-content.text-tips {
  display: block !important;
  padding-left: 0;
  margin: 0;
  line-height: 2;
  background: var(--el-color-white);
}
.table-bottom .right-content .bottom-tips {
  position: absolute;
  top: 0;
  left: 2vw;
  width: 7vw;
  height: 7vw;
  background: var(--el-base-icons) -25px -113px;
}

a{
  font-size: 4vw;
  color: #008bd5 !important;
}}
</style>