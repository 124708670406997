<template>
  <location :content-nav-list="contentNavList"></location>
  <div class="single-container">
    <ArticleContent
      :content="columnInfo.singlePageContent"
      :attachment-file-list="columnInfo.attachmentFileList"
    />
  </div>
</template>

<script setup>
// #region 引用
import ArticleContent from '@/views/components/website/article-content/ArticleContent.vue';
import { getColumnPageUrl } from '@/utils/business/website';
import useSSRInitialStateStore from '@/stores/ssr-state';
import { useSSRContext } from 'vue';
import location from '../components/Location.vue';

// #endregion

// #region props/emit

// #endregion

// #region 变量/常量
// 栏目信息

// 导航地址信息
const contentNavList = ref([]);
const columnInfo = ref();
const previewId = ref();
// #endregion

// #region 生命周期

// #endregion

// #region 业务方法

onServerPrefetch(async () => {
  const ssrContext = useSSRContext();
  columnInfo.value = ssrContext.initialState.data.current.columnInfo;
  previewId.value = ssrContext.initialState.data.current.previewId;
  contentNavList.value.push({
    title: columnInfo.value.columnName,
    url: getColumnPageUrl(
      columnInfo.value.columnType,
      columnInfo.value.id,
      previewId.value,
    ),
  });
  ssrContext.initialState.data.current.contentNavList = contentNavList.value;

  // #endregion
});

/**
 * 获取栏目内容
 */
if (!import.meta.env.SSR) {
  const ssrInitialState = useSSRInitialStateStore();
  columnInfo.value = ssrInitialState.data.current.columnInfo;
  previewId.value = ssrInitialState.data.current.previewId;
  contentNavList.value = ssrInitialState.data.current.contentNavList;
}

// #endregion
</script>
<style scoped>
@media screen and (device-width >= 1180px) {
  .single-container {
    width: 1200px;
    padding: 30px 40px;
    margin: 0 auto 28px;
    overflow: hidden;
    box-shadow: 0 0 6px rgb(35 52 78 / 30%);
  }
}
</style>
<style scoped>
@media screen and (device-width <= 1180px) {
  .single-container {
    padding: 2vw;
    overflow: hidden;
    font-size: 4vw;
    box-shadow: 0 0 6px rgb(35 52 78 / 30%);
  }
}
</style>
