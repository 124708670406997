<template>
  <div
    class="image-text-container"
    @mouseenter="handleShowOperationBtn(props.showEditContentButton)"
    @mouseleave="handleShowOperationBtn(false)"
  >
    <div class="image-text">
      <span class="image-text-container-image">
        <img :src="configDataForm.imageFullUrl" />
      </span>
      <span class="image-text-container-content">
        <div class="image-text-container-content-title">
          <div class="image-text-container-content-title-main-title">
            {{ configDataForm.title }}
          </div>
          <div
            v-if="configDataForm.subTitle"
            class="image-text-container-content-title-about-us"
          >
            {{ configDataForm.subTitle }}
          </div>
        </div>

        <div
          class="image-text-container-content-content"
          v-html="configDataForm.content"
        ></div>
        <div
          v-if="configDataForm.linkUrl"
          class="image-text-container-content-more"
        >
          <a
            target="_blank"
            :href="configDataForm.linkUrl"
            :style="{
              'background-color': configDataForm.backgroundColor,
              'border-color': configDataForm.textColor,
              color: configDataForm.textColor,
            }"
          >
            → 了解更多
          </a>
        </div>
      </span>
    </div>
    <operationContainer
      v-if="showOperationBtn && !showEditForm"
      :component-data="props.componentData"
      :show-edit-content-button="props.showEditContentButton"
      :show-edit-components-button="props.showEditComponentsButton"
      :show-delete-components-button="props.showDeleteComponentsButton"
      :show-insert-components-button="props.showInsertComponentsButton"
      :show-sort-components-button="props.showSortComponentsButton"
      @handle-show-edit-form="handleShowEditForm"
      @handle-change-components="handleChangeComponents"
      @handle-delete-components="handleDeleteComponents"
      @handle-insert-components="handleInsertComponents"
      @handle-sort-components="handleSortComponents"
    ></operationContainer>
  </div>
  <el-drawer
    v-model="showEditForm"
    size="60%"
    :title="props.componentData.componentName"
  >
    <image-text-edit-form
      v-if="showEditForm"
      v-model="configDataForm"
      :component-data="props.componentData"
      @handle-show-edit-form="handleShowEditForm"
      @handle-edit-components-content="handleEditComponentsContent"
    ></image-text-edit-form>
  </el-drawer>
</template>
<script setup>
// #region 引用
import OperationContainer from '../common/OperationContainer.vue';
import ImageTextEditForm from './ImageTextEditFormEleven.vue';
// #endregion

// #region props/emit
/**
 * 属性
 */
const props = defineProps({
  showEditContentButton: {
    type: Boolean,
    default: true,
  },
  showEditComponentsButton: {
    type: Boolean,
    default: false,
  },
  showDeleteComponentsButton: {
    type: Boolean,
    default: false,
  },
  showInsertComponentsButton: {
    type: Boolean,
    default: false,
  },
  showSortComponentsButton: {
    type: Boolean,
    default: false,
  },
  componentData: {
    type: Object,
    default() {},
  },
});

// console.log('props-configDataForm:',props);

/**
 * 方法
 */
const emits = defineEmits([
  'handle-show-edit-form',
  'handle-change-components',
  'handle-delete-components',
  'handle-insert-components',
  'handle-sort-components',
  'handle-edit-components-content',
  'update:modelValue',
]);

// #endregion

// #region 变量/常量

// 表单绑定对象
const configDataForm = ref({
  contentImageObj: null,
  imageName: '',
  imageFileKey: 'contentImage',
  backGroundImageObj: null,
  backgroundImageName: '',
  backgroundImageFileKey: 'backgroundImage',
  title: '示例标题',
  content: '示例内容',
  textColor: '#ffffff',
  backgroundColor: '#008bd5',
  linkUrl: '',
});

if (props.componentData.configData) {
  console.log('props-configDataForm:', props.componentData.configData);
  // 服务器渲染的直接调用
  configDataForm.value = JSON.parse(props.componentData.configData);
  // 后台编辑的处理一下
  // nextTick(() => {
  //   configDataForm.value = JSON.parse(props.componentData.configData);
  // });
}

// 操作显示按钮控制
const showOperationBtn = ref(false);
// 显示编辑表单
const showEditForm = ref(false);

// #endregion

// #region 生命周期

// #endregion

// #region 业务方法
/**
 * 显示
 */
const handleShowOperationBtn = (flag) => {
  showOperationBtn.value = flag;
};
/**
 * 显示编辑表单
 */
const handleShowEditForm = (flag) => {
  showEditForm.value = flag;
};
/**
 * 处理编辑表单内容
 */
const handleEditComponentsContent = (flag) => {
  // console.log('configDataForm--1:', configDataForm);
  // 待数据更新完成后才执行
  nextTick(() => {
    emits('update:modelValue', configDataForm.value);
    emits('handle-edit-components-content', true);
  });

  handleShowEditForm(flag);
};

/**
 * 改变组件
 */
const handleChangeComponents = () => {
  emits('handle-change-components', true);
};

/**
 * 删除组件
 */
const handleDeleteComponents = () => {
  emits('handle-delete-components', true);
};

/**
 * 插入组件
 */
const handleInsertComponents = () => {
  emits('handle-insert-components');
};
/**
 * 组件排序
 */
const handleSortComponents = (moveObj) => {
  emits('handle-sort-components', moveObj);
};

// #endregion
</script>
<style scoped>
@media screen and (device-width >= 1180px) {
  .image-text-container-content-title {
    margin-bottom: 0;
    border-bottom: unset;
  }
}
</style>

<style scoped>
@media screen and (device-width <= 1180px) {
  .image-text-container-content-title {
    margin-bottom: 0;
    border-bottom: unset;
  }

  .image-text {
    flex-wrap: wrap-reverse;
  }
}
</style>
